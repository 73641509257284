import * as React from 'react';

//material
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
    Card,
    CardContent,
    Grid,
    CardHeader,
    CardActions,
    Divider,
    Tooltip
} from "@mui/material"
import { QuestionMarkOutlined, TimerOutlined } from "@mui/icons-material"

//router
import { useNavigate,  } from "react-router-dom"

//apollo
import { useQuery } from "@apollo/client"
import { LOAD_QUESTIONNAIRE_OPTIONS } from "../../queries/admin/questionnaireQueries"

//components
import { LoadingSkeleton, TableNoData } from "../../utils/tableUtils"

//date
import { formatDuration, intervalToDuration } from "date-fns"

//styles
import selectQuestionnaireStyles from "../../assets/styles/selectQuestionnaireStyles"
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"
import { grayColor } from "../../assets/styles/utils"

export default function UserSelectQuestionnaire() {
    const navigate = useNavigate()

    const { data, loading } = useQuery(LOAD_QUESTIONNAIRE_OPTIONS)

    const TableHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar disableGutters sx={quizLayoutStyles.componentHeaderToolbar}>
                    <Box>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            Select questionnaire
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        );
    }

    const QuestionnaireItem = ({ item }) => {

        function handleSelectQuestionnaire(item) {
            navigate(`/quiz/questionnaire/${item.id}`)
        }

        return (
            <Card variant={"outlined"} sx={selectQuestionnaireStyles.card} onClick={() => handleSelectQuestionnaire(item)}>
                <CardHeader
                    disableTypography
                    title={
                        <Typography variant={"h6"}>
                            {item.topic.name}
                        </Typography>
                    }
                    subheader={
                        <Typography variant={"caption"}>
                            { item.topic.description}
                        </Typography>
                    }
                    sx={{ padding: "8px 8px 0 8px" }}
                />
                <CardContent sx={{ p: 1 }}>
                    <Typography variant={"body1"}>
                        {item.description}
                    </Typography>
                </CardContent>
                <CardActions sx={{ p: 1, display: "flex", justifyContent: "flex-end" }}>
                    <Box
                        display={"inline-flex"}
                        sx={{
                            color: "white",
                            p: "1px",
                            borderRadius: "3px",
                            alignItems: "center",
                            backgroundColor: grayColor[0]
                        }}
                    >
                        <Tooltip title={`Time to complete: ${formatDuration(intervalToDuration({
                            start: 0,
                            end: item.time * 1000
                        }))}`}>
                            <TimerOutlined fontSize={"small"} sx={{ margin: "0 3px" }} />
                        </Tooltip>
                        {intervalToDuration({
                            start: 0,
                            end: item.time * 1000
                        }).minutes + ":" + intervalToDuration({ start: 0, end: item.time * 1000 }).seconds}
                        <Divider
                            orientation={"vertical"}
                            sx={{
                                margin: "2px 4px",
                                display: "inline-flex",
                                width: 0,
                                height: "25px",
                                border: 0,
                                borderLeft: 2
                            }}
                        />
                        {item.questionnaireQuestionnaireQuestion.edges.length}
                        <Tooltip title={`Questions quantity: ${item.questionnaireQuestionnaireQuestion.edges.length}`}>
                            <QuestionMarkOutlined fontSize={"small"} sx={{ margin: "0 3px" }} />
                        </Tooltip>
                    </Box>
                </CardActions>
            </Card>
        )
    }

    return (
        <Box sx={{width: "100%"}}>
            <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                <TableHeaderBar/>
                <CardContent>
                    {
                        loading
                            ? <LoadingSkeleton/>
                            : !data?.allQuestionnaires?.edges?.length
                                ? <TableNoData/>
                                : <Grid container spacing={1} justifyContent={"center"} alignItems={"flex-start"}>
                                    {
                                        data.allQuestionnaires.edges.map(
                                            item => (
                                                <Grid
                                                    key={item.node.id}
                                                    container
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                    justifyContent={"center"}
                                                >
                                                    <QuestionnaireItem item={item.node} />
                                                </Grid>
                                            )
                                        )
                                    }
                                </Grid>
                    }
                </CardContent>
            </Card>
        </Box>
    )
}