import * as React from "react"

//material
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Checkbox from "@mui/material/Checkbox"
import IconButton from "@mui/material/IconButton"
import FormControlLabel from "@mui/material/FormControlLabel"
import {
    Button,
    Card,
    CardContent,
    Collapse,
    FormHelperText,
    Grid,
    InputAdornment,
    MenuItem,
    TextField, Tooltip
} from "@mui/material"
import { AddOutlined, RemoveOutlined } from "@mui/icons-material"

//transition
import { TransitionGroup } from "react-transition-group"

//formik
import { Field, Formik, getIn } from "formik"
import * as Yup from "yup"

//router
import { useLocation, useNavigate } from "react-router-dom"

//components
import { ErrorNotification, SuccessNotification } from "../../../components/Notifications/Notifications"

//apollo
import { useMutation, useQuery } from "@apollo/client"
import { CREATE_QUESTION, LOAD_ALL_QUESTIONS, UPDATE_QUESTION } from "../../../queries/admin/questionQueries"
import { CREATE_TOPIC, LOAD_ALL_TOPICS } from "../../../queries/admin/topicQueries"

//utils
import { getFormErrors } from "../../../utils/errorsUtils"

//styles
import quizLayoutStyles from "../../../assets/styles/quizLayoutStyles"
import { LoadingButton } from "@mui/lab"

export default function AdminQuestionForm() {
    const navigate = useNavigate()
    const location = useLocation()

    const updateItem = location.state?.updateItem

    const notifySuccess = message => SuccessNotification(message)
    const notifyError = message => ErrorNotification(message)

    const successForm = () => {
        updateItem ? notifySuccess("Question updated") : notifySuccess("Question added")
        navigate("/quiz/manage-questions")
    }

    const [createQuestion, {loading: loadingCreate}] = useMutation(CREATE_QUESTION, {
        refetchQueries: [ LOAD_ALL_QUESTIONS ]
    })
    const [updateQuestion, {loading: loadingUpdate}] = useMutation(UPDATE_QUESTION, {
        refetchQueries: [ LOAD_ALL_QUESTIONS ]
    })

    const FormHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar disableGutters sx={quizLayoutStyles.componentHeaderToolbar}>
                    <Box>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            {
                                updateItem ? "Edit question" : "Create question"
                            }
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        );
    };

    const [topic, setTopic] = React.useState("")
    const [description, setDescription] = React.useState("")
    const [explanation, setExplanation] = React.useState("")
    const [answers, setAnswers] = React.useState(
        [
            {description: "", isCorrect: false},
            {description: "", isCorrect: false},
            {description: "", isCorrect: false},
            {description: "", isCorrect: false},
        ]
    )
    const [urls, setUrls] = React.useState([])
    const [cites, setCites] = React.useState([])

    const [topicOptions, setTopicOptions] = React.useState([])
    useQuery(LOAD_ALL_TOPICS, {
        onCompleted: data => {
            if (data?.allTopics?.edges?.length) {
                setTopicOptions(data.allTopics.edges)
            }
        }
    })

    React.useEffect(() => {
        if (updateItem && topicOptions.length) {
           let itemIndex = topicOptions.findIndex(item => item.node.id === updateItem.topic.id)
            if (itemIndex > -1) {
                setTopic(topicOptions[itemIndex])
            }
        }
    }, [updateItem, topicOptions])

    React.useEffect(() => {
        if (updateItem) {
            if (updateItem.description) {
                setDescription(updateItem.description)
            }

            if (updateItem.explanation) {
                setExplanation(updateItem.explanation)
            }

            if (updateItem.questionPossibleAnswer.edges.length) {
                let answers = updateItem.questionPossibleAnswer.edges.map(item => ({description: item.node.description, isCorrect: item.node.isCorrect}))
                setAnswers([...answers])
            }

            if (updateItem.questionUrl.edges.length) {
                let urls = updateItem.questionUrl.edges.map(item => ({url: item.node.url}))
                setUrls([...urls])
            }

            if (updateItem.questionCite.edges.length) {
                let cites = updateItem.questionCite.edges.map(item => ({cite: item.node.cite}))
                setCites([...cites])
            }
        }
    }, [updateItem])


    //formik
    const initialValues = {
        topic: topic,
        description: description,
        explanation: explanation,
        answers: answers,
        urls: urls,
        cites: cites,
        topicName: "",
        topicDescription: "",
    }

    const validationSchema = Yup.object({
        topic: Yup.object().required('field required'),
        description: Yup.string().required('field required'),
        explanation: Yup.string().required('field required'),
        answers: Yup.array()
            .of(
                Yup.object({
                    description: Yup.string().required('field required'),
                    isCorrect: Yup.boolean(),
                })
            )
            .min(1)
            .test({
                name: "atLeastOneCorrectAnswer",
                exclusive: false,
                params: {},
                message: `at least one answer must be correct`,
                test: function (value) {
                    return value.reduce((previousValue, currentValue) => previousValue || currentValue.isCorrect, false)
                },
            }),
        urls: Yup.array().of(
            Yup.object({
                url: Yup.string().url("field must be a valid URL").required('url required'),
            })
        ),
        cites: Yup.array().of(
            Yup.object({
                cite: Yup.string().required('cite required'),
            })
        ),
        topicName: Yup.string(),
        topicDescription: Yup.string(),
    })

    const ErrorMessage = ({ name }) => (
        <Field name={name}>
            {(form) => {
                const error = getIn(form.form.errors, name)
                return <span style={{ color: "rgb(211, 47, 47)" }}>{error}</span>
            }}
        </Field>
    )

    function addPossibleAnswer(formik) {
        formik.setFieldValue("answers", [...formik.values.answers, {description: "", isCorrect: false}])
    }

    function addPossibleUrl(formik) {
        formik.setFieldValue("urls", [...formik.values.urls, {url: ""}])
    }

    function addPossibleCite(formik) {
        formik.setFieldValue("cites", [...formik.values.cites, {cite: ""}])
    }

    function deletePossibleAnswer(formik, index) {
        let values = formik.values.answers
        values.splice(index, 1)
        formik.setFieldValue("answers", [...values])
    }

    function deletePossibleUrl(formik, index) {
        let values = formik.values.urls
        values.splice(index, 1)
        formik.setFieldValue("urls", [...values])
    }

    function deletePossibleCite(formik, index) {
        let values = formik.values.cites
        values.splice(index, 1)
        formik.setFieldValue("cites", [...values])
    }

    const [displayTopicForm, setDisplayTopicForm] = React.useState(false)
    function toggleTopicForm() {
        setDisplayTopicForm(!displayTopicForm)
    }

    const TopicForm = (props) => {
        const {formik} = props

        const [createTopic, {loading: loadingCreateTopic}] = useMutation(CREATE_TOPIC, {
            refetchQueries: [ LOAD_ALL_TOPICS ]
        })

        function handleCreateTopic () {
            if (!formik.values.topicName) {
                formik.setFieldError("topicName", "field required")
            }

            if (!formik.values.topicDescription) {
                formik.setFieldError("topicDescription", "field required")
            }

            if (formik.values.topicName && formik.values.topicDescription) {
                createTopic({
                    variables: {
                        name: formik.values.topicName,
                        description: formik.values.topicDescription
                    }
                })
                    .then((response) => {
                        if (response?.data?.createTopic?.topic) {
                            notifySuccess("Topic added")
                            formik.setFieldValue("topicName", "")
                            formik.setFieldValue("topicDescription", "")
                            toggleTopicForm()
                        }
                    })
                    .catch(error => {
                        if (error.graphQLErrors.length) {
                            notifyError("Topic added failed, check information provided and try again")
                        } else {
                            notifyError("Topic added failed, try again")
                        }
                    })
            }
        }

        return (
            <Box sx={{width: 1, border: 1}}>
                <Grid container>
                    <TextField
                        name={"topicName"}
                        label="Topic"
                        variant={"filled"}
                        size={"small"}
                        fullWidth
                        sx={{ m: 1 }}
                        {...formik.getFieldProps("topicName")}
                        error={formik.errors.topicName && formik.touched.topicName}
                        helperText={(formik.errors.topicName && formik.touched.topicName) && formik.errors.topicName}
                    />

                    <TextField
                        name={"topicDescription"}
                        label="Description"
                        variant={"filled"}
                        size={"small"}
                        fullWidth
                        sx={{ m: 1 }}
                        {...formik.getFieldProps("topicDescription")}
                        error={formik.errors.topicDescription && formik.touched.topicDescription}
                        helperText={(formik.errors.topicDescription && formik.touched.topicDescription) && formik.errors.topicDescription}
                    />
                </Grid>

                <Grid container item xs={12} sx={{justifyContent: "right"}}>
                    <Button
                        color={"secondary"}
                        variant={"contained"}
                        size={"small"}
                        sx={{m: 1}}
                        onClick={toggleTopicForm}
                    >
                        Cancel
                    </Button>

                    <LoadingButton
                        color={"primary"}
                        variant={"contained"}
                        size={"small"}
                        sx={{m: 1}}
                        loading={loadingCreateTopic}
                        onClick={handleCreateTopic}
                    >
                        Create topic
                    </LoadingButton>
                </Grid>
            </Box>
        )
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(data, {setErrors}) => {
                const register = updateItem ? updateQuestion : createQuestion
                register({
                    variables: {
                        id: updateItem?.id ?? null,
                        topic: data.topic.node.id,
                        description: data.description,
                        explanation: data.explanation,
                        questionPossibleAnswerAdd: data.answers,
                        questionUrlAdd: data.urls,
                        questionCiteAdd: data.cites,
                    }
                })
                    .then((response) => {
                        const returned = updateItem ? response?.data?.updateQuestion : response?.data?.createQuestion
                        if (returned.question) {
                            successForm()
                        } else {
                            notifyError("Operation on question failed, try again")
                        }
                    })
                    .catch(error => {
                        if (error.graphQLErrors.length) {
                            getFormErrors(error.graphQLErrors, notifyError, setErrors)
                        } else {
                            notifyError("Operation on question failed, try again")
                        }
                    })
            }}
        >
            {
                formik => (
                    <Box sx={{width: "100%"}}>
                        <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                            <FormHeaderBar/>
                            <CardContent>
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid container item xs={12} spacing={2}>
                                                <Grid item xs={12} md={true}>
                                                    <TextField
                                                        name={"topic"}
                                                        label="Topic"
                                                        variant={"filled"}
                                                        size={"small"}
                                                        fullWidth
                                                        select
                                                        {...formik.getFieldProps("topic")}
                                                        error={formik.errors.topic && formik.touched.topic}
                                                        helperText={(formik.errors.topic && formik.touched.topic) && formik.errors.topic}
                                                    >
                                                        {topicOptions?.map((option) => (
                                                            <MenuItem key={option.node.id} value={option}>
                                                                {option.node.name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid container item xs={12} md={"auto"} alignItems={"center"}>
                                                    <Button
                                                        color={"inherit"}
                                                        variant={"contained"}
                                                        size={"small"}
                                                        startIcon={<AddOutlined fontSize={"small"}/>}
                                                        onClick={toggleTopicForm}
                                                    >
                                                        New topic
                                                    </Button>
                                                </Grid>

                                                <Grid container item xs={12}>
                                                    {displayTopicForm && <TopicForm formik={formik} />}
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    name={"description"}
                                                    label="Question"
                                                    variant={"filled"}
                                                    size={"small"}
                                                    fullWidth
                                                    {...formik.getFieldProps("description")}
                                                    error={formik.errors.description && formik.touched.description}
                                                    helperText={(formik.errors.description && formik.touched.description) && formik.errors.description}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    name={"explanation"}
                                                    label="Explanation"
                                                    variant={"filled"}
                                                    size={"small"}
                                                    multiline
                                                    fullWidth
                                                    rows={3}
                                                    {...formik.getFieldProps("explanation")}
                                                    error={formik.errors.explanation && formik.touched.explanation}
                                                    helperText={(formik.errors.explanation && formik.touched.explanation) && formik.errors.explanation}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12}>
                                            <Grid
                                                item
                                                xs={12}
                                                sx={
                                                    {
                                                        maxHeight: "calc(65vh)",
                                                        overflowY: "auto",
                                                        overflowX: "hidden",
                                                        border: 1,
                                                        p: 1,
                                                        boxSizing: "border-box"
                                                    }
                                                }
                                            >
                                                <TransitionGroup>
                                                    {
                                                        formik.values.urls.map((urlItem, index) => (
                                                            <Collapse key={index}>
                                                                <Grid container spacing={1} sx={{mb: "5px"}}>
                                                                    <Grid item xs={12} md={8}>
                                                                        <TextField
                                                                            name={`url-${index}`}
                                                                            label="Url"
                                                                            variant={"filled"}
                                                                            size={"small"}
                                                                            fullWidth
                                                                            {...formik.getFieldProps(`urls.${index}.url`)}
                                                                            error={
                                                                                !!(
                                                                                    formik.errors.urls &&
                                                                                    formik.errors.urls[index]?.url
                                                                                )
                                                                            }
                                                                            helperText={<ErrorMessage name={`urls.${index}.url`} />}
                                                                            InputProps={{
                                                                                endAdornment:
                                                                                    <IconButton
                                                                                        size={"small"}
                                                                                        aria-label="remove url"
                                                                                        onClick={() => deletePossibleUrl(formik, index)}
                                                                                    >
                                                                                        <RemoveOutlined fontSize={"small"}/>
                                                                                    </IconButton>
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Collapse>
                                                        ))
                                                    }
                                                </TransitionGroup>

                                                <Box sx={{display: "flex", m: 1, width: "100%"}}>
                                                    <Button
                                                        variant={"text"}
                                                        size={"small"}
                                                        onClick={() => addPossibleUrl(formik)}
                                                    >
                                                        Add URL
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12}>
                                            <Grid
                                                item
                                                xs={12}
                                                sx={
                                                    {
                                                        maxHeight: "calc(65vh)",
                                                        overflowY: "auto",
                                                        overflowX: "hidden",
                                                        border: 1,
                                                        p: 1,
                                                        boxSizing: "border-box"
                                                    }
                                                }
                                            >
                                                <TransitionGroup>
                                                    {
                                                        formik.values.cites.map((citeItem, index) => (
                                                            <Collapse key={index}>
                                                                <Grid container spacing={1} sx={{mb: "5px"}}>
                                                                    <Grid item xs={12} md={8}>
                                                                        <TextField
                                                                            name={`cite-${index}`}
                                                                            label="Cite"
                                                                            variant={"filled"}
                                                                            size={"small"}
                                                                            fullWidth
                                                                            {...formik.getFieldProps(`cites.${index}.cite`)}
                                                                            error={
                                                                                !!(
                                                                                    formik.errors.cites &&
                                                                                    formik.errors.cites[index]?.cite
                                                                                )
                                                                            }
                                                                            helperText={<ErrorMessage name={`cites.${index}.cite`} />}
                                                                            InputProps={{
                                                                                endAdornment:
                                                                                    <IconButton
                                                                                        size={"small"}
                                                                                        aria-label="remove cite"
                                                                                        onClick={() => deletePossibleCite(formik, index)}
                                                                                    >
                                                                                        <RemoveOutlined fontSize={"small"}/>
                                                                                    </IconButton>
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Collapse>
                                                        ))
                                                    }
                                                </TransitionGroup>

                                                <Box sx={{display: "flex", m: 1, width: "100%"}}>
                                                    <Button
                                                        variant={"text"}
                                                        size={"small"}
                                                        onClick={() => addPossibleCite(formik)}
                                                    >
                                                        Add Cite
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12}>
                                            <Grid
                                                item
                                                xs={12}
                                                sx={
                                                    {
                                                        maxHeight: "calc(65vh)",
                                                        overflowY: "auto",
                                                        overflowX: "hidden",
                                                        border: 1,
                                                        p: 1,
                                                        boxSizing: "border-box"
                                                    }
                                                }
                                            >
                                                <TransitionGroup>
                                                    {
                                                        formik.values.answers.map((answerItem, index) => (
                                                            <Collapse key={index}>
                                                                <Grid container spacing={1} sx={{mb: "5px"}}>
                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            name={`description-${index}`}
                                                                            label="Possible answer"
                                                                            variant={"filled"}
                                                                            size={"small"}
                                                                            fullWidth
                                                                            {...formik.getFieldProps(`answers.${index}.description`)}
                                                                            error={
                                                                                !!(
                                                                                    formik.errors.answers &&
                                                                                    formik.errors.answers[index]?.description
                                                                                )
                                                                            }
                                                                            helperText={<ErrorMessage name={`answers.${index}.description`} />}
                                                                            InputProps={{
                                                                                endAdornment:
                                                                                    <>
                                                                                        <Tooltip title={"Correct answer"}>
                                                                                            <Checkbox
                                                                                                color={"success"}
                                                                                                checked={answerItem.isCorrect}
                                                                                                {...formik.getFieldProps(`answers.${index}.isCorrect`)}
                                                                                                size={"small"}
                                                                                            />
                                                                                        </Tooltip>

                                                                                        <IconButton
                                                                                            size={"small"}
                                                                                            aria-label="remove answer"
                                                                                            onClick={() => deletePossibleAnswer(formik, index)}
                                                                                        >
                                                                                            <RemoveOutlined
                                                                                                fontSize={"small"} />
                                                                                        </IconButton>
                                                                                    </>

                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Collapse>
                                                        ))
                                                    }
                                                </TransitionGroup>

                                                <FormHelperText sx={{color: "#d32f2f", margin: "4px  14px 0 14px"}}>
                                                    {typeof formik.errors.answers === "string" && formik.errors.answers }
                                                </FormHelperText>

                                                <Box sx={{display: "flex", m: 1, width: "100%"}}>
                                                    <Button
                                                        variant={"text"}
                                                        size={"small"}
                                                        onClick={() => addPossibleAnswer(formik)}
                                                    >
                                                        Add possible answer
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} sx={{display: "flex", justifyContent: "right", p: 1, m: 1}}>
                                            <Button
                                                color={"secondary"}
                                                variant={"contained"}
                                                size={"small"}
                                                sx={{m: 1}}
                                                onClick={() => navigate("/quiz/manage-questions")}
                                            >
                                                Cancel
                                            </Button>

                                            <LoadingButton
                                                type={"submit"}
                                                color={"primary"}
                                                variant={"contained"}
                                                size={"small"}
                                                sx={{m: 1}}
                                                disabled={!formik.isValid}
                                                loading={loadingCreate || loadingUpdate}
                                            >
                                                {updateItem ? "Update" : "Create"}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Box>
                )
            }
        </Formik>
    )
}