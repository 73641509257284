import React from "react"

//router
import { Routes, Route, Outlet, Navigate } from "react-router-dom"

//redux
import { useSelector } from "react-redux"

//layout
import AuthLayout from "./layouts/AuthLayout"

//cookies
import Cookies from "js-cookie"

import LoginPage from "./views/PublicViews/LoginPage"
import Dashboard from "./views/AuthViews/Dashboard"
import AdminQuestionAnswer from "./views/AuthViews/AdminViews/AdminQuestionAnswer"
import AdminQuestionnaire from "./views/AuthViews/AdminViews/AdminQuestionnaire"
import AdminQuestionForm from "./views/AuthViews/AdminViews/AdminQuestionForm"
import AdminTopic from "./views/AuthViews/AdminViews/AdminTopic"
import AdminTopicForm from "./views/AuthViews/AdminViews/AdminTopicForm"
import AdminQuestionnaireForm from "./views/AuthViews/AdminViews/AdminQuestionnaireForm"
import UserSelectQuestionnaire from "./views/AuthViews/UserSelectQuestionnaire"
import UserExamStart from "./views/AuthViews/UserExamStart"
import UserTrainingExam from "./views/AuthViews/UserTrainingExam"
import UserProfile from "./views/AuthViews/UserProfile"
import UserProfileForm from "./views/AuthViews/UserProfileForm"
import UserChangePasswordForm from "./views/AuthViews/UserChangePasswordForm"
import VerifyAccountPage from "./views/PublicViews/VerifyAccountPage"
import UserTrainingExamSummary from "./views/AuthViews/UserTrainingExamSummary"
import DashboardTrainingExamsStats from "./views/AuthViews/DashboardTrainingExamsStats"
import DashboardTrainingQuestionnairesStats from "./views/AuthViews/DashboardTrainingQuestionnairesStats"
import RecoverPasswordPage from "./views/PublicViews/RecoverPasswordPage"
import ResetPasswordPage from "./views/PublicViews/ResetPasswordPage"
import UserSubscriptionPayment from "./views/AuthViews/UserSubscriptionPayment"
import UserSubscriptionPaymentForm from "./views/AuthViews/UserSubscriptionPaymentForm"
import UserSubscriptionPaymentDone from "./views/AuthViews/UserSubscriptionPaymentDone"
import AdminDashboard from "./views/AuthViews/AdminDashboard"
import CookiesAgreement from "./views/PublicViews/CookiesAgreement"

//toast notifications
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"

import UsageTermsPage from "./views/PublicViews/UsageTermsPage"
import AdminGlossary from "./views/AuthViews/AdminViews/AdminGlossary"
import AdminGlossaryForm from "./views/AuthViews/AdminViews/AdminGlossaryForm"
import UserGlossary from "./views/AuthViews/UserGlossary"
import UserExam from "./views/AuthViews/UserExam"
import UserExamSummary from "./views/AuthViews/UserExamSummary"
import UserAccountDevice from "./views/AuthViews/UserAccountDevice"
import DashboardExamsStats from "./views/AuthViews/DashboardExamsStats"
import UserExamSummaryDetails from "./views/AuthViews/UserExamSummaryDetails"

const PrivateRoutes = () => {
    const token = !!Cookies.get("JWT")
    const authUser = useSelector(state => state.loginReducer)

    return token && authUser ? <Outlet /> : <Navigate to={"/login"} />
};

//known if authenticated user is admin. a user is admin if isStaff = true or isSuperuser = true or belongs to a group named "admin"
function useIsAdminUser() {
    const authUser = useSelector(state => state.loginReducer)

    if (authUser && (authUser.isStaff || authUser.isSuperuser)) {
        return true
    }
    return authUser?.groups?.edges?.findIndex((group) => group.node.name.toUpperCase() === "ADMIN") > -1
}

function App() {
    return (
        <>
            <ToastContainer hideProgressBar newestOnTop closeOnClick={false} autoClose={7000}/>
            <Routes>
                <Route path={"quiz"} element={<PrivateRoutes/>} >
                    <Route path={"questionnaire"} element={<AuthLayout><UserSelectQuestionnaire /></AuthLayout>}/>
                    <Route path={"questionnaire/:questionnaireId"} element={<AuthLayout><UserExamStart /></AuthLayout>}/>
                    <Route path={"questionnaire/:questionnaireId/training/:trainingId"} element={<AuthLayout><UserTrainingExam /></AuthLayout>}/>
                    <Route path={"questionnaire/:questionnaireId/training/:trainingId/summary"} element={<AuthLayout><UserTrainingExamSummary /></AuthLayout>}/>

                    <Route path={"questionnaire/:questionnaireId/exam/:examId"} element={<AuthLayout><UserExam /></AuthLayout>}/>
                    <Route path={"questionnaire/:questionnaireId/exam/:examId/summary"} element={<AuthLayout><UserExamSummary /></AuthLayout>}/>
                    <Route path={"questionnaire/:questionnaireId/exam/:examId/answers-details"} element={<AuthLayout><UserExamSummaryDetails /></AuthLayout>}/>

                    <Route path={"profile"} element={<AuthLayout><UserProfile /></AuthLayout>}/>
                    <Route path={"profile/form"} element={<AuthLayout><UserProfileForm /></AuthLayout>}/>
                    <Route path={"profile/change-password"} element={<AuthLayout><UserChangePasswordForm /></AuthLayout>}/>
                    <Route path={"profile/device"} element={<AuthLayout><UserAccountDevice /></AuthLayout>}/>

                    <Route path={"subscription"} element={<AuthLayout><UserSubscriptionPayment /></AuthLayout>}/>
                    <Route path={"subscription/pay"} element={<AuthLayout><UserSubscriptionPaymentForm /></AuthLayout>}/>
                    <Route path={"subscription/pay/done"} element={<AuthLayout><UserSubscriptionPaymentDone /></AuthLayout>}/>

                    <Route path={"glossary"} element={<AuthLayout><UserGlossary /></AuthLayout>}/>

                    {/*for admin usage*/}
                    {
                        useIsAdminUser() && <>
                            <Route path={"manage-questions"} element={<AuthLayout><AdminQuestionAnswer /></AuthLayout>}/>
                            <Route path={"manage-questions/form"} element={<AuthLayout><AdminQuestionForm /></AuthLayout>}/>

                            <Route path={"manage-topics"} element={<AuthLayout><AdminTopic /></AuthLayout>}/>
                            <Route path={"manage-topics/form"} element={<AuthLayout><AdminTopicForm /></AuthLayout>}/>

                            <Route path={"manage-questionnaire"} element={<AuthLayout><AdminQuestionnaire /></AuthLayout>}/>
                            <Route path={"manage-questionnaire/form"} element={<AuthLayout><AdminQuestionnaireForm /></AuthLayout>}/>

                            <Route path={"manage-glossary"} element={<AuthLayout><AdminGlossary /></AuthLayout>}/>
                            <Route path={"manage-glossary/form"} element={<AuthLayout><AdminGlossaryForm /></AuthLayout>}/>

                            <Route path={"admin-stats"} element={<AuthLayout><AdminDashboard /></AuthLayout>}/>
                        </>
                    }
                    {/*for admin usage*/}

                    <Route path={"dashboard/training-exam-stats"} element={<AuthLayout><DashboardTrainingExamsStats /></AuthLayout>}/>
                    <Route path={"dashboard/training-questionnaire-stats"} element={<AuthLayout><DashboardTrainingQuestionnairesStats /></AuthLayout>}/>

                    <Route path={"dashboard/exam-stats"} element={<AuthLayout><DashboardExamsStats /></AuthLayout>}/>

                    <Route path={"*"} element={<AuthLayout><Dashboard /></AuthLayout>}/>

                    <Route index element={<AuthLayout><Dashboard /></AuthLayout>}/>
                </Route>

                <Route path={"/usage-terms"} element={<UsageTermsPage />} />
                <Route path={"/account/verify/:token"} element={<VerifyAccountPage />} />
                <Route path={"/account/recover-password"} element={<RecoverPasswordPage />} />
                <Route path={"/account/reset-password/:token"} element={<ResetPasswordPage />} />
                <Route path={"/*"} element={<LoginPage />} exact />
            </Routes>
            <CookiesAgreement/>
        </>
    )
}

export default App
