import * as React from 'react';

//material
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
} from "@mui/material"
import { PaymentOutlined } from "@mui/icons-material"

//router
import { useNavigate,  } from "react-router-dom"

//apollo
import { useQuery } from "@apollo/client"
import { I_PAID } from "../../queries/auth/userSubscriptionsQueries"

//styles
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"

//utils
import selectQuestionnaireStyles from "../../assets/styles/selectQuestionnaireStyles"
import { whiteColor } from "../../assets/styles/utils"

//dates
import { format } from "date-fns"


export default function UserSubscriptionPayment() {
    const navigate = useNavigate()

    const { data, loading } = useQuery(I_PAID, {fetchPolicy: "network-only"})

    const TableHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar disableGutters sx={quizLayoutStyles.componentHeaderToolbar}>
                    <Box>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                            }}
                        >
                            Subscription payment
                        </Typography>
                    </Box>
                    <Box sx={{display: "flex"}}>
                        {
                            loading
                                ? <CircularProgress size={24}/>
                                : <Button
                                    color={"primary"}
                                    variant={"contained"}
                                    onClick={() => navigate("/quiz/subscription/pay")}
                                    startIcon={<PaymentOutlined/>}
                                >
                                    Pay subscription
                                </Button>
                        }
                    </Box>
                </Toolbar>
            </AppBar>
        );
    }

    return (
        <Box sx={{width: "100%"}}>
            <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                <TableHeaderBar/>
                <CardContent>
                    <Grid container item xs={12} spacing={1}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item sx={selectQuestionnaireStyles.leftFlex}>Status:</Grid>
                            <Grid item sx={selectQuestionnaireStyles.rightFlex}>
                                <Box sx={
                                    {
                                        p: "3px 5px",
                                        borderRadius: "3px",
                                        color: whiteColor,
                                        backgroundColor: data?.iPaid?.isActive ? "success.main" : "error.main"
                                    }
                                }
                                >
                                    {data?.iPaid?.isActive ? "active" : "inactive"}
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} spacing={1}>
                            <Grid item sx={selectQuestionnaireStyles.leftFlex}>Subscription plan:</Grid>
                            <Grid item sx={selectQuestionnaireStyles.rightFlex}>
                                {data?.iPaid?.isActive && data?.iPaid?.type ? data?.iPaid?.type : "-"}
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} spacing={1}>
                            <Grid item sx={selectQuestionnaireStyles.leftFlex}>Active until:</Grid>
                            <Grid item sx={selectQuestionnaireStyles.rightFlex}>
                                {
                                    data?.iPaid?.isActive && data?.iPaid?.subscriptionActiveUntil
                                        ? format(new Date(data?.iPaid?.subscriptionActiveUntil), "yyyy/MM/dd hh:mm:ss")
                                        : "-"
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}