import { gql } from "@apollo/client"

//all questions
export const LOAD_ALL_QUESTIONS = gql`
    query allQuestions (
        $orderBy: [String]
        $first: Int
        $last: Int
        $beforeCursor: String
        $afterCursor: String
        $descriptionFilter: String
        $explanationFilter: String
    ){
        allQuestions (
            orderBy: $orderBy
            first: $first
            last: $last
            before: $beforeCursor
            after: $afterCursor
            description_Icontains: $descriptionFilter
            explanation_Icontains: $explanationFilter
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    topic {
                        id
                        name
                        description
                    }
                    description
                    explanation
                    questionPossibleAnswer {
                        edges {
                            node {
                                id
                                description
                                isCorrect
                            }
                        }
                    }
                    questionUrl{
                        edges{
                            node{
                                id
                                url
                            }
                        }
                    }
                    questionCite{
                        edges{
                            node{
                                id
                                cite
                            }
                        }
                    }
                    questionFeedback{
                        edges{
                            node{
                                id
                                client{
                                    user{
                                        id
                                        username
                                    }
                                }
                                feedback
                            }
                        }
                    }
                }
            }
        }
    }`

//create question with nested possible answers
export const CREATE_QUESTION = gql`
    mutation createQuestion(
        $topic: ID!, 
        $description: String, 
        $explanation: String, 
        $questionPossibleAnswerAdd: [CreateQuestionInputAddQuestionQuestionpossibleanswer], 
        $questionUrlAdd: [CreateQuestionInputAddQuestionQuestionurl], 
        $questionCiteAdd: [CreateQuestionInputAddQuestionQuestioncite]
    ) {
        createQuestion(
            input: { 
                topic: $topic, 
                description: $description, 
                explanation: $explanation, 
                questionPossibleAnswerAdd: $questionPossibleAnswerAdd
                questionUrlAdd: $questionUrlAdd
                questionCiteAdd: $questionCiteAdd 
            }
        ) {
            question {
                id
                topic {
                    id
                    description
                }
                description
                explanation
                questionPossibleAnswer {
                    edges {
                        node {
                            id
                            description
                            isCorrect
                        }
                    }
                }
            }
        }
    }
`

//update question
export const UPDATE_QUESTION = gql`
    mutation updateQuestion(
        $id: ID!, 
        $topic: ID!, 
        $description: String, 
        $explanation: String, 
        $questionPossibleAnswerAdd: [UpdateQuestionInputExactQuestionQuestionpossibleanswer]
        $questionUrlAdd: [UpdateQuestionInputExactQuestionQuestionurl],
        $questionCiteAdd: [UpdateQuestionInputExactQuestionQuestioncite]
    ){
        updateQuestion(
            id: $id, 
            input: {
                topic: $topic, 
                description: $description, 
                explanation: $explanation, 
                questionPossibleAnswer: $questionPossibleAnswerAdd
                questionUrl: $questionUrlAdd
                questionCite: $questionCiteAdd
            }
        ) {
            question {
                id
                topic {
                    id
                    description
                }
                description
                explanation
                questionPossibleAnswer {
                    edges {
                        node {
                            id
                            description
                            isCorrect
                        }
                    }
                }                                                                                                                                                                                               
            }
        }
    }`

//delete question
export const DELETE_QUESTION = gql`
    mutation deleteQuestion($id: ID!){
        deleteQuestion(id: $id) {
            found
            deletedId
        }
    }`