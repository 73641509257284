import { gql } from "@apollo/client"

//register payment
export const I_PAID = gql`
    query iPaid {
        iPaid {
            isActive
            type
            subscriptionActiveUntil
        }
    }
`
