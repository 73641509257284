const quizLayoutStyles = {
    background: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "calc(100vh)",
        width: "calc(100vw)",
        p: "15px",
        boxSizing: "border-box",
        backgroundColor: "#798fa1",
    },

    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        minWidth: {
            xs: "calc(100vw - 32px)",
            sm: "calc(100vw - 32px)",
            md: "calc(95vw - 32px)",
            lg: "calc(85vw - 32px)",
            xl: "calc(75vw - 32px)",
        },
        maxWidth: {
            xs: "calc(100vw - 32px)",
            sm: "calc(100vw - 32px)",
            md: "calc(95vw - 32px)",
            lg: "calc(85vw - 32px)",
            xl: "calc(75vw - 32px)",
        },
        minHeight: {
            xs: "calc(100vh - 30px)",
            sm: "calc(100vh - 30px)",
            md: "calc(90vh)",
            lg: "calc(70vh)",
            xl: "calc(50vh)",
        },
        borderRadius: 2,
        boxSizing: "border-box",
        backgroundColor: "#23395d",
    },

    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        width: "100%",
        height: "100px",
        p: "10px",
    },

    headerButton: {
        m: 1,
        color: "white",
        backgroundColor: "info.main",
        "&:hover": {
            backgroundColor: "success.main",
            color: "white",
        }
    },

    headerButtonSelected: {
        m: 1,
        color: "white",
        backgroundColor: "success.main",
        "&:hover": {
            backgroundColor: "success.main",
        }
    },

    headerDividerButton: {
        m: 3,
        height: "40px",
        margin: "2px 4px",
        display: "inline-flex",
        width: 0,
        border: 0,
        borderLeft: "2px solid white"
    },

    content: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        width: "100%",
        minHeight: {
            xs: "calc(65vh)",
            sm: "calc(65vh)",
            md: "calc(65vh)",
            lg: "calc(65vh)",
            xl: "calc(65vh)",
        },
        p: "10px 8px",
    },

    footer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        width: "100%",
        height: "70px",
        color: "white",
        p: 2,
    },

    componentHeaderToolbar: {
        p: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        minHeight: "40px !important",
        maxWidth: "100%",
        overflowX: "auto"
    },

    componentCardContainer: {
        backgroundColor: "white",
        borderRadius: 0
    }
}

export default quizLayoutStyles