import * as React from 'react';

//material
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
    Card,
    CardContent,
    Grid,
    Button
} from "@mui/material"
import { LoadingButton } from "@mui/lab"
import {
    DescriptionOutlined,
    InfoOutlined,
    QuestionAnswerOutlined,
    TimerOutlined,
    TopicOutlined
} from "@mui/icons-material"

//router
import { useNavigate, useParams } from "react-router-dom"

//apollo
import { useMutation, useQuery } from "@apollo/client"
import { LOAD_QUESTIONNAIRE_DETAILS } from "../../queries/admin/questionnaireQueries"
import { INITIALIZE_TRAINING, INITIALIZE_EXAM } from "../../queries/auth/userExamQueries"

//redux
import { useSelector } from "react-redux"

//components
import { ErrorNotification } from "../../components/Notifications/Notifications"
import { LoadingSkeleton } from "../../utils/tableUtils"

//date
import { formatDuration, intervalToDuration } from "date-fns"

//styles
import selectQuestionnaireStyles from "../../assets/styles/selectQuestionnaireStyles"
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"


export default function UserExamStart(props) {
    const navigate = useNavigate()

    const { questionnaireId } = useParams()
    const authUser = useSelector((state) => state.loginReducer)

    const notifyError = message => ErrorNotification(message)

    const { data, loading } = useQuery(LOAD_QUESTIONNAIRE_DETAILS, {variables: {id: questionnaireId}})
    const [initializeTraining, { loading: loadingInitializeTraining }] = useMutation(INITIALIZE_TRAINING)
    const [initializeExam, { loading: loadingInitializeExam }] = useMutation(INITIALIZE_EXAM)

    const TableHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar disableGutters sx={quizLayoutStyles.componentHeaderToolbar}>
                    <Box>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            Quiz details
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        );
    }

    function handleStartTraining() {
        initializeTraining({
            variables: {
                clientId: authUser.client.id,
                questionnaireId: questionnaireId,
            }
        }).then(response => {
            if (response?.data?.createExam?.exam?.id) {
                navigate(`/quiz/questionnaire/${questionnaireId}/training/${response.data.createExam.exam.id}`)
            } else {
                notifyError("Something was wrong while training initialization, try again")
            }
        }).catch((e) => {
            if (e.graphQLErrors && e.graphQLErrors[0]?.message === "Not authorized, you must pay") {
                notifyError("You must pay subscription to be able of use all our services")
            } else notifyError("Something was wrong while training initialization, try again")
        })
    }

    function handleStartExam() {
        initializeExam({
            variables: {
                clientId: authUser.client.id,
                questionnaireId: questionnaireId,
            }
        }).then(response => {
            if (response?.data?.createExamReal?.examReal?.id) {
                navigate(`/quiz/questionnaire/${questionnaireId}/exam/${response.data.createExamReal.examReal.id}`)
            } else {
                notifyError("Something was wrong while exam initialization, try again")
            }
        }).catch((e) => {
            if (e.graphQLErrors && e.graphQLErrors[0]?.message === "Not authorized, you must pay") {
                notifyError("You must pay subscription to be able of use all our services")
            } else notifyError("Something was wrong while training initialization, try again")
        })
    }

    return (
        <Box sx={{width: "100%"}}>
            <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                <TableHeaderBar/>
                <CardContent>
                    {
                        loading
                            ? <LoadingSkeleton/>
                            : <>
                                <Grid container spacing={1}>
                                    <Grid container item xs={12} spacing={1}>
                                        <Grid container item xs={12} spacing={1}>
                                            <Grid item sx={selectQuestionnaireStyles.leftFlex}>Topic:</Grid>
                                            <Grid item sx={selectQuestionnaireStyles.rightFlex}>
                                                <TopicOutlined sx={{mr: 1, color: "primary.main"}}/>
                                                {data?.questionnaire?.topic?.name ?? "-"}
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={1}>
                                            <Grid item sx={selectQuestionnaireStyles.leftFlex}>Topic about:</Grid>
                                            <Grid item sx={selectQuestionnaireStyles.rightFlex}>
                                                <TopicOutlined sx={{mr: 1, color: "primary.main"}}/>
                                                {data?.questionnaire?.topic?.description ?? "-"}
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={1}>
                                            <Grid item sx={selectQuestionnaireStyles.leftFlex}>Quiz about:</Grid>
                                            <Grid item sx={selectQuestionnaireStyles.rightFlex}>
                                                <DescriptionOutlined sx={{mr: 1, color: "primary.main"}}/>
                                                {data?.questionnaire?.description ?? "-"}
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={1}>
                                            <Grid item sx={selectQuestionnaireStyles.leftFlex}>Quiz time:</Grid>
                                            <Grid item sx={selectQuestionnaireStyles.rightFlex}>
                                                <TimerOutlined sx={{mr: 1, color: "primary.main"}}/>
                                                {
                                                    data?.questionnaire?.time
                                                        ? formatDuration(intervalToDuration({ start: 0, end: data.questionnaire.time * 1000}))
                                                        : "-"
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={1}>
                                            <Grid item sx={selectQuestionnaireStyles.leftFlex}>Quiz questions:</Grid>
                                            <Grid item sx={selectQuestionnaireStyles.rightFlex}>
                                                <QuestionAnswerOutlined sx={{mr: 1, color: "primary.main"}}/>
                                                {data?.questionnaire?.questionnaireQuestionnaireQuestion?.edges.length ?? "-"}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12}>
                                        <Grid container item xs={"auto"} alignItems={"center"} sx={{mr: 1}}>
                                            <InfoOutlined/>
                                        </Grid>
                                        <Grid item xs={true}>
                                            <Typography variant={"body1"} textAlign={"justify"}>
                                                Press start button when you ready to start quiz. Once the exam has started
                                                you need to keep an eye on the time remaining on the clock. You can always
                                                finish answering all the questions even if time runs out. Test your
                                                knowledge and Good luck.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Box sx={{display: "flex", justifyContent: "right", p: 1, m: 1}}>
                                    <Button
                                        color={"secondary"}
                                        variant={"contained"}
                                        size={"small"}
                                        sx={{m: 1}}
                                        onClick={() => navigate("/quiz/questionnaire")}
                                    >
                                        Cancel
                                    </Button>

                                    <LoadingButton
                                        color={"primary"}
                                        type="submit"
                                        variant={"contained"}
                                        size={"small"}
                                        sx={{m: 1}}
                                        loading={loadingInitializeTraining}
                                        onClick={handleStartTraining}
                                    >
                                        Start training
                                    </LoadingButton>

                                    <LoadingButton
                                        color={"primary"}
                                        type="submit"
                                        variant={"contained"}
                                        size={"small"}
                                        sx={{m: 1}}
                                        loading={loadingInitializeExam}
                                        onClick={handleStartExam}
                                    >
                                        Start exam
                                    </LoadingButton>
                                </Box>
                            </>
                    }
                </CardContent>
            </Card>
        </Box>
    )
}