import * as React from 'react';

//material
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    TextField
} from "@mui/material"
import { LoadingButton } from "@mui/lab"

//router
import { useNavigate } from "react-router-dom"
import { useMutation, useQuery } from "@apollo/client"

//formik
import { Formik } from "formik"
import * as Yup from "yup"

//apollo
import { USER_DETAILS } from "../../queries/auth/userQueries"
import { UPDATE_USER_ACCOUNT } from "../../queries/auth/loginQueries"

//components
import { SuccessNotification, ErrorNotification } from "../../components/Notifications/Notifications"

//styles
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"

export default function UserProfileForm() {
    const navigate = useNavigate()

    const { data, loading } = useQuery(USER_DETAILS)

    const notifySuccess = () => SuccessNotification("Update account succeed")
    const notifyError = () => ErrorNotification("Update account failed")

    const [updateAccount, {loading: loadingUpdate}] = useMutation(
        UPDATE_USER_ACCOUNT,
        {
            refetchQueries: [{ query: USER_DETAILS }]
        }
    )

    const TableHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar disableGutters sx={quizLayoutStyles.componentHeaderToolbar}>
                    <Grid container>
                        <Grid item xs={true}>
                            <Typography
                                variant="h6"
                                noWrap
                                sx={{
                                    mr: 2,
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                }}
                            >
                                Edit profile
                            </Typography>
                        </Grid>
                        <Grid item xs={"auto"}>
                            {
                                loading && <CircularProgress size={24}/>
                            }
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }

    const initialValues = {
        firstName: data?.me2?.firstName ?? "",
        lastName: data?.me2?.lastName ?? "",
    }

    const validationSchema = Yup.object({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
    })

    return (
        <Box sx={{width: "100%"}}>
            <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                <TableHeaderBar/>
                <CardContent>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize
                        onSubmit={data => {
                            updateAccount({
                                variables: {
                                    firstName: data.firstName,
                                    lastName: data.lastName
                                }
                            }).then(response => {
                                if (response?.data?.updateAccount?.success) {
                                    notifySuccess()
                                    navigate("/quiz/profile")
                                } else {
                                    notifyError()
                                }
                            })
                        }}
                    >
                        {
                            formik => (
                                <form onSubmit={formik.handleSubmit}>
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    name="firstName"
                                                    label="Name*"
                                                    variant={"filled"}
                                                    size={"small"}
                                                    fullWidth
                                                    {...formik.getFieldProps("firstName")}
                                                    error={formik.errors.firstName && formik.touched.firstName}
                                                    helperText={
                                                        formik.errors.firstName &&
                                                        formik.touched.firstName &&
                                                        formik.errors.firstName
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    name="lastName"
                                                    label="Last name*"
                                                    variant={"filled"}
                                                    size={"small"}
                                                    fullWidth
                                                    {...formik.getFieldProps("lastName")}
                                                    error={formik.errors.lastName && formik.touched.lastName}
                                                    helperText={
                                                        formik.errors.lastName &&
                                                        formik.touched.lastName &&
                                                        formik.errors.lastName
                                                    }
                                                />
                                            </Grid>
                                        </Grid>

                                        <Box sx={{display: "flex", justifyContent: "right", p: 1, m: 1}}>
                                            <Button
                                                color={"warning"}
                                                size={"small"}
                                                variant={"outlined"}
                                                sx={{m: 1, mr: 2}}
                                                onClick={() => navigate("/quiz/profile/change-password")}
                                            >
                                                Change password
                                            </Button>

                                            <Button
                                                color={"secondary"}
                                                variant={"contained"}
                                                size={"small"}
                                                sx={{m: 1}}
                                                onClick={() => navigate("/quiz/profile")}
                                            >
                                                Cancel
                                            </Button>

                                            <LoadingButton
                                                color={"primary"}
                                                type="submit"
                                                variant={"contained"}
                                                size={"small"}
                                                sx={{m: 1}}
                                                loading={loadingUpdate}
                                            >
                                                Update
                                            </LoadingButton>
                                        </Box>
                                    </Box>
                                </form>
                            )
                        }
                    </Formik>
                </CardContent>
            </Card>
        </Box>
    )
}