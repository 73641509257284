import * as React from 'react';

//material
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip
} from "@mui/material"
import {
    DeleteOutline,
    PermDeviceInformationOutlined
} from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"

//router
import { useNavigate,  } from "react-router-dom"

//apollo
import { useApolloClient, useQuery } from "@apollo/client"
import { Mutation } from "@apollo/client/react/components"
import { DELETE_DEVICE, USER_DEVICES } from "../../queries/auth/loginQueries"

//styles
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"

//redux
import { useDispatch, useSelector } from "react-redux"

import { ConfirmTransition } from "../../utils/tableUtils"
import { ErrorNotification, SuccessNotification } from "../../components/Notifications/Notifications"
import { singOut } from "../../actions/auth/loginActions"


export default function UserAccountDevice() {
    const navigate = useNavigate()
    const authUser = useSelector((state) => state.loginReducer)
    const dispatch = useDispatch()
    const client = useApolloClient()

    const notifySuccess = message => SuccessNotification(message)
    const notifyError = message => ErrorNotification(message)

    const { data, loading, refetch } = useQuery(
        USER_DEVICES,
        {
            variables: {
                clientId: authUser.client.id,
            }
        }
    )

    const currentDevice = navigator.userAgent.replace(/\d/g, "")

    const TableHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar disableGutters sx={quizLayoutStyles.componentHeaderToolbar}>
                    <Grid container>
                        <Grid item xs={true}>
                            <Typography
                                variant="h6"
                                noWrap
                                sx={{
                                    mr: 2,
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                }}
                            >
                                Account devices
                            </Typography>
                        </Grid>
                        <Grid item xs={"auto"}>{loading && <CircularProgress size={24}/>}</Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }

    const [openDialog, setOpenDialog] = React.useState(false)
    const [deleteItem, setDeleteItem] = React.useState(null)

    const handleDeleteItem = (e, item) => {
        setDeleteItem(item)
        setOpenDialog(true)
    }

    function ConfirmDialog(props) {
        const {register, refetch, loading, openDialog, setOpenDialog} = props

        const handleClose = () => {
            setOpenDialog(false)
        }

        const handleDelete = () => (
            register({
                variables: {
                    id: deleteItem.node.id
                }
            }).then(
                (response) => {
                    if (response.data.deleteDevice.found) {
                        notifySuccess("Account linked device deleted")
                        refetch()
                        if (deleteItem.node.code === currentDevice) {
                            dispatch(singOut())
                            client.clearStore()
                        }
                    }
                    handleClose()
                },
                () => {
                    notifyError("Delete account linked device failed, try again")
                    handleClose()
                }
            )
        )


        return (
            <div>
                <Dialog
                    open={openDialog}
                    TransitionComponent={ConfirmTransition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>
                        Delete account linked device
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            Do you confirm to delete the device?
                        </Typography>
                        {
                            deleteItem?.node?.code === currentDevice
                            && <small>
                                If you delete this device, user will be log out from system.
                            </small>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary" disabled={loading}>
                            Cancel
                        </Button>
                        <LoadingButton
                            color={"primary"}
                            variant={"contained"}
                            loading={loading}
                            onClick={handleDelete}
                        >
                            Confirm
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }


    return (
        <Mutation mutation={DELETE_DEVICE}>
            {
                (register, {loading: loadingDelete}) => (
                    <Box sx={{width: "100%"}}>
                        <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                            <TableHeaderBar/>
                            <ConfirmDialog
                                openDialog={openDialog}
                                setOpenDialog={setOpenDialog}
                                register={register}
                                refetch={refetch}
                                loading={loadingDelete}
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    {
                                        data?.allDevices?.edges?.map((item, index) => (
                                            <Grid item container xs={12} spacing={1} key={item.node.id}>
                                                <Grid container item xs={"auto"} sx={{fontWeight: "600"}} alignItems={"center"}>
                                                    {`Device # ${index + 1}`}:
                                                </Grid>

                                                <Grid container item xs={true} alignItems={"center"}>
                                                    <div>
                                                        {item.node.code}
                                                        {
                                                            currentDevice === item.node.code
                                                            && <Tooltip title={"this device"}>
                                                                <PermDeviceInformationOutlined
                                                                    color={"success"}
                                                                    fontSize={"small"}
                                                                    sx={{ml: 1}}
                                                                />
                                                            </Tooltip>
                                                        }
                                                    </div>
                                                </Grid>

                                                <Grid container item xs={"auto"} alignItems={"center"}>
                                                    <Tooltip title={"Delete device"}>
                                                        <IconButton color={"error"} size={"small"} onClick={(e) => handleDeleteItem(e, item)}>
                                                            <DeleteOutline fontSize={"small"}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                )
            }
        </Mutation>
    )
}