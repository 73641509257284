import * as React from 'react';

//material
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
    Card,
    CardContent,
    CircularProgress,
    Grid,
    IconButton, Tooltip
} from "@mui/material"
import { DevicesOutlined, EditOutlined } from "@mui/icons-material"

//router
import { useNavigate,  } from "react-router-dom"

//apollo
import { useQuery } from "@apollo/client"
import { USER_DETAILS } from "../../queries/auth/userQueries"

//styles
import userProfileStyles from "../../assets/styles/userProfileStyles"
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"

export default function UserProfile() {
    const navigate = useNavigate()

    const { data, loading } = useQuery(USER_DETAILS)

    const TableHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar disableGutters sx={quizLayoutStyles.componentHeaderToolbar}>
                    <Box>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                            }}
                        >
                            User profile
                        </Typography>
                    </Box>
                    <Box sx={{display: "flex"}}>
                        {
                            loading
                                ? <CircularProgress size={24} />
                                : <>
                                    <Tooltip title={"Edit profile data"}>
                                        <IconButton onClick={() => navigate("/quiz/profile/form")}>
                                            <EditOutlined />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={"Account devices"}>
                                        <IconButton onClick={() => navigate("/quiz/profile/device")}>
                                            <DevicesOutlined />
                                        </IconButton>
                                    </Tooltip>
                                </>

                        }
                    </Box>
                </Toolbar>
            </AppBar>
        );
    }

    return (
        <Box sx={{width: "100%"}}>
            <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                <TableHeaderBar/>
                <CardContent>
                   <Grid container spacing={1}>
                       <Grid container item xs={12} md={6} spacing={1}>
                           <Grid item container xs={12} spacing={1}>
                               <Grid item sx={userProfileStyles.leftFlex}>
                                   User:
                               </Grid>
                               <Grid item sx={userProfileStyles.rightFlex}>
                                   {data?.me2?.username ?? "-"}
                               </Grid>
                           </Grid>

                           <Grid item container xs={12} spacing={1}>
                               <Grid item sx={userProfileStyles.leftFlex}>
                                   Name:
                               </Grid>
                               <Grid item sx={userProfileStyles.rightFlex}>
                                   {data?.me2?.firstName ?? "-"}
                               </Grid>
                           </Grid>

                           <Grid item container xs={12} spacing={1}>
                               <Grid item sx={userProfileStyles.leftFlex}>
                                   Lastname:
                               </Grid>
                               <Grid item sx={userProfileStyles.rightFlex}>
                                   {data?.me2?.lastName ?? "-"}
                               </Grid>
                           </Grid>
                       </Grid>

                       <Grid container item xs={12} md={6} spacing={1}>
                           <Grid item container xs={12} spacing={1}>
                               <Grid item sx={userProfileStyles.leftFlex}>
                                   Email:
                               </Grid>
                               <Grid item sx={userProfileStyles.rightFlex}>
                                   {data?.me2?.email ?? "-"}
                               </Grid>
                           </Grid>

                           <Grid item container xs={12} spacing={1}>
                               <Grid item sx={userProfileStyles.leftFlex}>
                                   Date joined:
                               </Grid>
                               <Grid item sx={userProfileStyles.rightFlex}>
                                   {data?.me2?.dateJoined ? new Date(data.me2.dateJoined).toDateString() : "-"}
                               </Grid>
                           </Grid>

                           <Grid item container xs={12} spacing={1}>
                               <Grid item sx={userProfileStyles.leftFlex}>
                                   Last login:
                               </Grid>
                               <Grid item sx={userProfileStyles.rightFlex}>
                                   {data?.me2?.lastLogin ? new Date(data.me2.lastLogin).toDateString() : "-"}
                               </Grid>
                           </Grid>
                       </Grid>
                   </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}