import * as React from "react"

//material
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Checkbox from "@mui/material/Checkbox"
import {
    Button,
    Card,
    CardContent,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    TextField, Tooltip
} from "@mui/material"
import { CloseOutlined, DragHandle, RemoveCircleOutline, SearchOutlined } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"

//formik
import { Field, Formik, getIn } from "formik"
import * as Yup from "yup"

//router
import { useLocation, useNavigate } from "react-router-dom"

//components
import { ErrorNotification, SuccessNotification } from "../../../components/Notifications/Notifications"

//apollo
import { useMutation, useQuery } from "@apollo/client"
import { CREATE_QUESTIONNAIRE, LOAD_ALL_QUESTIONNAIRES, UPDATE_QUESTIONNAIRE } from "../../../queries/admin/questionnaireQueries"

//utils
import { getFormErrors } from "../../../utils/errorsUtils"
import { LOAD_ALL_TOPICS } from "../../../queries/admin/topicQueries"
import { LOAD_ALL_QUESTIONS } from "../../../queries/admin/questionQueries"

//styles
import { grayColor } from "../../../assets/styles/utils"
import quizLayoutStyles from "../../../assets/styles/quizLayoutStyles"

//transition
import { TransitionGroup } from "react-transition-group"

//drag list
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export default function AdminQuestionnaireForm() {
    const navigate = useNavigate()
    const location = useLocation()

    const updateItem = location.state?.updateItem

    const notifySuccess = message => SuccessNotification(message)
    const notifyError = message => ErrorNotification(message)

    const successForm = () => {
        updateItem ? notifySuccess("Questionnaire updated") : notifySuccess("Questionnaire added")
        navigate("/quiz/manage-questionnaire")
    }

    const [createQuestionnaire, {loading: loadingCreate}] = useMutation(CREATE_QUESTIONNAIRE, {
        refetchQueries: [ LOAD_ALL_QUESTIONNAIRES ]
    })
    const [updateQuestionnaire, {loading: loadingUpdate}] = useMutation(UPDATE_QUESTIONNAIRE, {
        refetchQueries: [ LOAD_ALL_QUESTIONNAIRES ]
    })

    const FormHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar disableGutters sx={quizLayoutStyles.componentHeaderToolbar}>
                    <Box>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            {
                                updateItem ? "Edit questionnaire" : "Create questionnaire"
                            }
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        );
    };

    const [topic, setTopic] = React.useState("")
    const [description, setDescription] = React.useState("")
    const [time, setTime] = React.useState("")
    const [questions, setQuestions] = React.useState([])

    const [topicOptions, setTopicOptions] = React.useState([])
    useQuery(LOAD_ALL_TOPICS, {
        onCompleted: data => {
            if (data?.allTopics?.edges?.length) {
                setTopicOptions(data.allTopics.edges)
            }
        }
    })

    const [questionOptions, setQuestionOptions] = React.useState([])
    useQuery(LOAD_ALL_QUESTIONS, {
        onCompleted: data => {
            if (data?.allQuestions?.edges?.length) {
                setQuestionOptions(data.allQuestions.edges)
            }
        }
    })

    function handleSelected(formik, item) {
        let selectedItems = formik.values.questions

        const index = selectedItems.findIndex(selectedItem => selectedItem.node.id === item.node.id)

        if (index > -1) {
            selectedItems.splice(index, 1)
        } else {
            selectedItems.push(item)
        }

        formik.setFieldValue("questions", [...selectedItems])
    }

    function handleSelectAll(e, formik) {
        const checked = e.target.checked

        let selectedItems = formik.values.questions

        if (formik.values.filterValue) {
            const targetItems = questionOptions.filter(item => (
                    (item.node.topic.name.toUpperCase().indexOf(formik.values.filterValue.toUpperCase()) > -1) ||
                    (item.node.description.toUpperCase().indexOf(formik.values.filterValue.toUpperCase()) > -1)
                )
            )

            for (const questionOption of targetItems) {
                const index = selectedItems.findIndex(selectedItem => selectedItem.node.id === questionOption.node.id)
                if (index === -1 && checked) {
                    selectedItems.push(questionOption)
                }

                if (index > -1 && !checked) {
                    selectedItems.splice(index, 1)
                }
            }

            formik.setFieldValue("questions", [...selectedItems])
        } else {
            if (checked) {
                for (const questionOption of questionOptions) {
                    const index = selectedItems.findIndex(selectedItem => selectedItem.node.id === questionOption.node.id)
                    if (index === -1) {
                        selectedItems.push(questionOption)
                    }
                }

                formik.setFieldValue("questions", [...selectedItems])
            } else {
                formik.setFieldValue("questions", [])
            }
        }
    }


    function handleDismissItem(formik, item) {
        let selectedItems = formik.values.questions
        const index = selectedItems.findIndex(selectedItem => selectedItem.node.id === item.node.id)

        selectedItems.splice(index, 1)

        formik.setFieldValue("questions", [...selectedItems])
    }

    //formik
    const initialValues = {
        topic: topic,
        description: description,
        time: time,
        questions: questions,
        filterValue: "",
    }

    const validationSchema = Yup.object({
        topic: Yup.object().required('field required'),
        description: Yup.string().required('field required'),
        time: Yup.number().integer("seconds field must be an integer number").required('field required'),
        questions: Yup.array().of(Yup.object()).min(1),
        filterValue: Yup.string(),
    })

    React.useEffect(() => {
        if (updateItem) {
            if (updateItem.description) {
                setDescription(updateItem.description)
            }

            if (updateItem.time) {
                setTime(updateItem.time)
            }
        }
    }, [updateItem])

    React.useEffect(() => {
        if (updateItem && topicOptions.length) {
            let itemIndex = topicOptions.findIndex(item => item.node.id === updateItem.topic.id)
            if (itemIndex > -1) {
                setTopic(topicOptions[itemIndex])
            }
        }
    }, [updateItem, topicOptions])

    React.useEffect(() => {
        if (updateItem?.questionnaireQuestionnaireQuestion?.edges?.length > 0 && questionOptions.length > 0) {
            let questions = []
            updateItem.questionnaireQuestionnaireQuestion.edges.forEach(item => {
                let itemIndex = questionOptions.findIndex(questionOption => questionOption.node.id === item.node.question.id)
                if (itemIndex > -1) {
                    questions.push(questionOptions[itemIndex])
                }
            })
            setQuestions(questions)
        }
    }, [updateItem, topicOptions, questionOptions])

    const QuestionSelectList = ({ formik, items }) => (
        <Card variant={"outlined"} sx={{ maxHeight: "calc(65vh)", overflow: 'auto', p: 1 }}>
            <Typography variant={"subtitle1"} sx={{ mb: 1 }}>
                { `Select questions from ${questionOptions.length} available` }
            </Typography>
            <Grid container spacing={1}>
                <Grid container item xs={12} columnSpacing={1}>
                    <Grid item xs={"auto"}>
                        <Tooltip title={"Select or unselect all that match filter criteria"}>
                            <Checkbox size={"small"} onChange={(e) => handleSelectAll(e, formik)}/>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={true}>
                        <TextField
                            name={"filterValue"}
                            variant={"outlined"}
                            size={"small"}
                            placeholder={"Question filter"}
                            fullWidth
                            {...formik.getFieldProps("filterValue")}
                            error={formik.errors.filterValue && formik.touched.filterValue}
                            helperText={(formik.errors.filterValue && formik.touched.filterValue) && formik.errors.filterValue}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlined fontSize={"small"}/>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size={"small"}
                                            onClick={() => formik.setFieldValue("filterValue", "") }
                                        >
                                            <CloseOutlined sx={{fontSize: "16px"}}/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ErrorMessage name={"questions"}/>
                    <List dense component="div" role="list">
                        {items
                            .filter(
                                item => (
                                    (item.node.topic.name.toUpperCase().indexOf(formik.values.filterValue.toUpperCase()) > -1) ||
                                    (item.node.description.toUpperCase().indexOf(formik.values.filterValue.toUpperCase()) > -1)
                                )
                            )
                            .map((option) => {
                            const labelId = `question-select-${option.node.id}-label`;

                            return (
                                <ListItem
                                    key={option.node.id}
                                    role="listitem"
                                    button
                                    onClick={() => handleSelected(formik, option)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={(formik.values.questions.findIndex(item => item.node.id === option.node.id) > -1)}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={option.node.description} secondary={option.node.topic.name}/>
                                </ListItem>
                            )
                        })}
                        <ListItem />
                    </List>
                </Grid>
            </Grid>
        </Card>
    )


    // Function to update list on drop
    const handleDrop = (formik, droppedItem) => {
        let updatedList = [...formik.values.questions]

        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)

        if (droppedItem.destination) {
            updatedList.splice(droppedItem.destination.index, 0, reorderedItem)
        }

        formik.setFieldValue("questions", updatedList)
    }

    const SelectedQuestions = ({ formik }) => (
        <Card variant={"outlined"} sx={{ p: 1, pl: 2 }}>
            <Typography variant={"subtitle1"} sx={{ mb: 1 }}>
                {`${formik.values.questions.length} questions selected`}
            </Typography>
            <DragDropContext onDragEnd={(droppedItem) => handleDrop(formik, droppedItem)}>
                <Droppable droppableId="list-container">
                    {(provided) => (
                        <Grid
                            container
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            sx={{maxHeight: "calc(65vh - 35px)", overflowY: "auto"}}
                        >
                            {formik.values.questions.map((item, index) => (
                                <Draggable key={item.node.id} draggableId={item.node.id} index={index}>
                                    {(provided) => (
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            columnSpacing={1}
                                            sx={{backgroundColor: grayColor[5], mt: "3px", mb: "3px", borderRadius: "5px 0 0 0"}}
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                        >
                                            <Grid container item xs={"auto"} alignItems={"center"}>
                                                <DragHandle fontSize={"small"} />
                                            </Grid>
                                            <Grid container item xs={true} alignItems={"center"}>
                                                {`${index + 1}. ${item.node.description}`}
                                            </Grid>
                                            <Grid container item xs={"auto"} alignItems={"center"}>
                                                <IconButton size={"small"} onClick={() => handleDismissItem(formik, item)}>
                                                    <RemoveCircleOutline fontSize={"small"} />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Grid>
                    )}
                </Droppable>
            </DragDropContext>
        </Card>
    )

    const ErrorMessage = ({ name }) => (
        <Field name={name}>
            {(form) => {
                const error = getIn(form.form.errors, name)
                return <FormHelperText sx={{ color: "rgb(211, 47, 47)" }}>{error}</FormHelperText>
            }}
        </Field>
    )

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(data, {setErrors}) => {
                const register = updateItem ? updateQuestionnaire : createQuestionnaire
                register({
                    variables: {
                        id: updateItem?.id ?? null,
                        topic: data.topic.node.id,
                        description: data.description,
                        time: data.time,
                        questionnaireQuestionnaireQuestion: data.questions.map((item, index) => ({question: item.node.id, order: index + 1})),
                    }
                })
                    .then((response) => {
                        const returned = updateItem ? response?.data?.updateQuestionnaire : response?.data?.createQuestionnaire
                        if (returned.questionnaire) {
                            successForm()
                        } else {
                            notifyError("Operation on questionnaire failed, try again")
                        }
                    })
                    .catch(error => {
                        if (error.graphQLErrors.length) {
                            getFormErrors(error.graphQLErrors, notifyError, setErrors)
                        } else {
                            notifyError("Operation on questionnaire failed, try again")
                        }
                    })
            }}
        >
            {
                formik => (
                    <Box sx={{width: "100%"}}>
                        <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                            <FormHeaderBar/>
                            <CardContent>
                                <form onSubmit={formik.handleSubmit}>
                                    <Box sx={{display: "flex", width: 1}}>
                                        <TextField
                                            name={"topic"}
                                            label="Topic"
                                            variant={"filled"}
                                            size={"small"}
                                            select
                                            sx={{m: 1, width: 2/3}}
                                            {...formik.getFieldProps("topic")}
                                            error={formik.errors.topic && formik.touched.topic}
                                            helperText={(formik.errors.topic && formik.touched.topic) && formik.errors.topic}
                                        >
                                            {topicOptions?.map((option) => (
                                                <MenuItem key={option.node.id} value={option}>
                                                    {option.node.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                        <TextField
                                            name={"time"}
                                            label="Seconds to fulfill"
                                            variant={"filled"}
                                            size={"small"}
                                            sx={{m: 1, width: 1/3}}
                                            inputProps={{inputMode: "numeric", pattern: '[0-9]*'}}
                                            {...formik.getFieldProps("time")}
                                            error={formik.errors.time && formik.touched.time}
                                            helperText={(formik.errors.time && formik.touched.time) && formik.errors.time}
                                        />
                                    </Box>

                                    <Box sx={{display: "flex", width: 1}}>
                                        <TextField
                                            name={"description"}
                                            label="Description"
                                            variant={"filled"}
                                            size={"small"}
                                            multiline
                                            rows={2}
                                            sx={{ m: 1, width: 1 }}
                                            {...formik.getFieldProps("description")}
                                            error={formik.errors.description && formik.touched.description}
                                            helperText={(formik.errors.description && formik.touched.description) && formik.errors.description}
                                        />
                                    </Box>

                                    <Grid container spacing={1} sx={{p: 1}}>
                                        <Grid item xs={12} md={8} lg={6}>
                                            <QuestionSelectList formik={formik} items={questionOptions}/>
                                        </Grid>

                                        <Grid item xs={12} md={4} lg={6}>
                                            <SelectedQuestions formik={formik}/>
                                        </Grid>
                                    </Grid>

                                    <Box sx={{display: "flex", justifyContent: "right", p: 1, m: 1}}>
                                        <Button
                                            color={"secondary"}
                                            variant={"contained"}
                                            size={"small"}
                                            sx={{m: 1}}
                                            onClick={() =>  navigate("/quiz/manage-questionnaire")}
                                        >
                                            Cancel
                                        </Button>

                                        <LoadingButton
                                            type={"submit"}
                                            color={"primary"}
                                            variant={"contained"}
                                            size={"small"}
                                            sx={{m: 1}}
                                            disabled={!formik.isValid}
                                            loading={loadingCreate || loadingUpdate}
                                        >
                                            {updateItem ? "Update" : "Create"}
                                        </LoadingButton>
                                    </Box>
                                </form>
                            </CardContent>
                        </Card>
                    </Box>
                )
            }
        </Formik>
    )
}