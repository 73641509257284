import * as React from "react"

//material ui
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import {
    Card,
    CardContent,
    Grid,
    Button, Avatar
} from "@mui/material"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import { TimelapseOutlined } from "@mui/icons-material"

//router
import { useNavigate } from "react-router-dom"

//apollo
import { useQuery } from "@apollo/client"
import { CLIENT_EXAM_STATS } from "../../queries/auth/userExamQueries"

//redux
import { useSelector } from "react-redux"

//notifications
import { ErrorNotification } from "../../components/Notifications/Notifications"

//components
import { LoadingSkeleton, TableNoData } from "../../utils/tableUtils"

//date
import { formatDuration, intervalToDuration } from "date-fns"

//styles
import tableStyles from "../../assets/styles/tableStyles"
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"
import { grayColor } from "../../assets/styles/utils"

export default function DashboardTrainingQuestionnairesStats(props) {
    const navigate = useNavigate()
    const authUser = useSelector((state) => state.loginReducer)

    const notifyError = message => ErrorNotification(message)

    const [data, setData] = React.useState()
    const {loading} = useQuery(
        CLIENT_EXAM_STATS,
        {
            variables: {id: authUser.client.id},
            onCompleted: data1 => {
                if (data1?.client?.id) {
                    const response = data1.client

                    let differentQuestionnaires = []
                    response.clientExam?.edges.forEach(item => {
                        const index = differentQuestionnaires.findIndex(differentItem => differentItem.questionnaire.questionnaire.id === item.node.questionnaire.id)
                        if (index === -1) {
                            differentQuestionnaires.push(
                                {
                                    questionnaire: item.node,
                                    timesCompleted: item.node.finishTime ? 1 : 0,
                                    bestCompletedTime: item.node.finishTime ? item.node.finishTime : null,
                                }
                            )
                        } else {
                            if (item.node.finishTime) {
                                differentQuestionnaires[index].timesCompleted =  differentQuestionnaires[index].timesCompleted + 1
                                if (
                                    !differentQuestionnaires[index].bestCompletedTime ||
                                    item.node.finishTime < differentQuestionnaires[index].bestCompletedTime
                                ) {
                                    differentQuestionnaires[index].bestCompletedTime = item.node.finishTime
                                }
                            }
                        }
                    })

                    setData(differentQuestionnaires)
                }
            },
            onError: () => {
                notifyError("Load questionnaires stats failed, try again")
            },
            fetchPolicy: "network-only",
        }
    )

    const TableHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar disableGutters sx={quizLayoutStyles.componentHeaderToolbar}>
                    <Box>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            Training Questionnaires stats
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        );
    }

    function EnhancedTableHead(props) {
        const headCells = [
            {
                id: 'number',
                label: '#',
            },
            {
                id: 'questionnaire',
                label: 'Questionnaire',
            },
            {
                id: 'timesCompleted',
                label: 'Times completed',
            },
            {
                id: 'completedTime',
                label: 'Completed best time',
            },
        ]

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={'left'}
                            padding={'normal'}
                        >
                            { headCell.label }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    return (
        <Box sx={{width: "100%"}}>
            <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                <TableHeaderBar/>
                <CardContent>
                    <Grid container spacing={1}>
                        {
                            loading
                                ? <LoadingSkeleton/>
                                : !data?.length
                                    ? <TableNoData/>
                                    : <TableContainer sx={{ maxHeight: "calc(70vh)" }}>
                                        <Table
                                            sx={{ minWidth: 750 }}
                                            aria-labelledby="tableTitle"
                                            size={'small'}
                                        >
                                            <EnhancedTableHead />
                                            <TableBody>
                                                {data.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            tabIndex={-1}
                                                            key={row.questionnaire.id}
                                                            sx={tableStyles.row}
                                                        >
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>{row.questionnaire.questionnaire.topic.name}</TableCell>
                                                            <TableCell>
                                                                <Avatar sx={{width: "32px", height: "32px"}}>
                                                                    {
                                                                        row.timesCompleted
                                                                    }
                                                                </Avatar>
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    row.bestCompletedTime
                                                                        ? <Box
                                                                            display={"inline-flex"}
                                                                            sx={{
                                                                                color: "white",
                                                                                p: "3px 5px",
                                                                                borderRadius: "3px",
                                                                                backgroundColor: grayColor[1]
                                                                            }}
                                                                        >
                                                                            <TimelapseOutlined fontSize={"small"}
                                                                                               sx={{ mr: 1 }} />
                                                                            {
                                                                                formatDuration(intervalToDuration({
                                                                                    start: 0,
                                                                                    end: row.bestCompletedTime * 1000
                                                                                }))
                                                                            }
                                                                        </Box>
                                                                        : "-"
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                        }
                    </Grid>

                    <Box sx={{display: "flex", justifyContent: "right", p: 1, m: 1}}>
                        <Button
                            type={"submit"}
                            color={"primary"}
                            variant={"contained"}
                            size={"small"}
                            sx={{m: 1}}
                            onClick={() => navigate("/quiz/dashboard")}
                        >
                            Return
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}