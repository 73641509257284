import * as React from "react"

//material
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TableSortLabel,
    TextField
} from "@mui/material"
import {
    AddOutlined,
    CancelOutlined,
    DeleteOutlined,
    EditOutlined,
    SearchOffOutlined,
    SearchOutlined
} from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"

//router
import { useNavigate } from "react-router-dom"

//apollo
import { useQuery } from "@apollo/client"
import { Mutation } from "@apollo/client/react/components"
import { DELETE_TOPIC, LOAD_ALL_TOPICS } from "../../../queries/admin/topicQueries"

//styles
import tableStyles from "../../../assets/styles/tableStyles"
import quizLayoutStyles from "../../../assets/styles/quizLayoutStyles"

//components
import { ErrorNotification, SuccessNotification } from "../../../components/Notifications/Notifications"
import { ConfirmTransition, LoadingSkeleton, TableNoData } from "../../../utils/tableUtils"
import { visuallyHidden } from "@mui/utils"
import TablePagination from "@mui/material/TablePagination"
import TableFooter from "@mui/material/TableFooter"

export default function AdminTopic() {
    const navigate = useNavigate()

    const notifySuccess = message => SuccessNotification(message)
    const notifyError = message => ErrorNotification(message)

    //pagination
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('name')
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)

    //save server response data (page info)
    const [totalItemsPagination, setTotalItemsPagination] = React.useState(-1)
    const [startCursorPagination, setStartCursorPagination] = React.useState(null)
    const [endCursorPagination, setEndCursorPagination] = React.useState(null)

    const [queryVariables, setQueryVariables] = React.useState({
        orderBy: order === "asc" ? [orderBy] : [`-${orderBy}`],
        nameFilter: null,
        first: rowsPerPage,
        last: null,
        beforeCursor: null,
        afterCursor: null
    })

    const setPaginationResponse = (data) => [
        setTotalItemsPagination(data.allTopics.totalCount),
        setStartCursorPagination(data.allTopics.pageInfo.startCursor),
        setEndCursorPagination(data.allTopics.pageInfo.endCursor)
    ]

    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            setQueryVariables({
                ...queryVariables,

                first: rowsPerPage,
                last: null,
                beforeCursor: null,
                afterCursor: endCursorPagination
            })
        } else {
            setQueryVariables({
                ...queryVariables,

                first: null,
                last: rowsPerPage,
                beforeCursor: startCursorPagination,
                afterCursor: null
            })
        }
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)

        setQueryVariables({
            ...queryVariables,

            first: parseInt(event.target.value, 10),
            last: null,
            beforeCursor: null,
            afterCursor: null
        })
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
        setPage(0)

        setQueryVariables({
            ...queryVariables,

            orderBy: order === "asc" ? [property] : [`-${property}`],
            first: rowsPerPage,
            last: null,
            beforeCursor: null,
            afterCursor: null
        })
    }

    const handleSearchInputChange = (nameValue) => {
        setPage(0)

        setQueryVariables({
            ...queryVariables,

            nameFilter: nameValue,

            first: rowsPerPage,
            last: null,
            beforeCursor: null,
            afterCursor: null
        })
    }

    //display search field
    const [showSearchInput, setShowSearchInput] = React.useState(false)
    const toggleSearchInput = () => {
        setShowSearchInput(!showSearchInput)
    }

    //search field
    const [searchValue, setSearchValue] = React.useState("")

    const handleSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }

    const { data, loading, refetch } = useQuery(LOAD_ALL_TOPICS, {
        variables: queryVariables,
        onCompleted: data1 => {
            setPaginationResponse(data1)
        },
        fetchPolicy: "network-only"
    })

    const TableHeaderBar = (props) => {
        const {
            handleSearchInputChange,
            searchValue,
            handleSearchValueChange,
            showSearchInput,
            toggleSearchInput,
        } = props

        const handlePressEnter = (e) => {
            if (e.keyCode === 13) {
                applySearch()
            }
        }

        const applySearch = () => {
            handleSearchInputChange(searchValue)
            toggleSearchInput()
        }

        const cancelFilters = () => {
            setSearchValue("")
            handleSearchInputChange("")
            toggleSearchInput()
        }

        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar disableGutters sx={quizLayoutStyles.componentHeaderToolbar}>
                    {
                        showSearchInput
                            ? <TextField
                                placeholder={"Search by topic..."}
                                autoFocus
                                size={"small"}
                                fullWidth
                                InputProps={{
                                    endAdornment:
                                        <Tooltip title={"Cancel search"}>
                                            <IconButton size={"small"} onClick={cancelFilters}>
                                                <CancelOutlined />
                                            </IconButton>
                                        </Tooltip>,
                                    startAdornment:
                                        <Tooltip title={"Search by topic"}>
                                            <IconButton size={"small"} onClick={applySearch}>
                                                <SearchOutlined />
                                            </IconButton>
                                        </Tooltip>


                                }}
                                value={searchValue}
                                onChange={handleSearchValueChange}
                                onKeyDown={handlePressEnter}
                            />
                            : <>
                                <Box>
                                    <Typography
                                        variant="h6"
                                        noWrap
                                        sx={{
                                            mr: 2,
                                            fontFamily: "monospace",
                                            fontWeight: 700,
                                            letterSpacing: ".3rem",
                                            color: "inherit",
                                            textDecoration: "none"
                                        }}
                                    >
                                        Manage topics
                                    </Typography>
                                </Box>

                                <Box sx={{display: "flex"}}>
                                    <Tooltip title="Filter data">
                                        <IconButton
                                            aria-label="filter data"
                                            color={searchValue ? "secondary" : "default"}
                                            onClick={toggleSearchInput}
                                        >
                                            {
                                                searchValue ? <SearchOutlined /> : <SearchOffOutlined />
                                            }
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Add topic">
                                        <IconButton onClick={() => navigate("/quiz/manage-topics/form")}>
                                            <AddOutlined />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </>
                    }
                </Toolbar>
            </AppBar>
        )
    }

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } = props

        const headCells = [
            {
                id: "name",
                label: "Name",
                sort: true,
            },
            {
                id: "description",
                label: "Description",
                sort: true,
            }
        ]

        const createSortHandler = (property) => () => {
            onRequestSort(property);
        }

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={"left"}
                            padding={'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {
                                headCell.sort
                                    ? <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : "asc"}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === "desc" ? "sorted descending" : "sorted ascending"}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                    : headCell.label
                            }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }

    const updateItem = (item) => {
        navigate("/quiz/manage-topics/form", { state: { updateItem: item } })
    }

    const [openDialog, setOpenDialog] = React.useState(false)
    const [deleteItemIndex, setDeleteItemIndex] = React.useState(null)

    const deleteItem = (item) => {
        setDeleteItemIndex(item.node.id)
        setOpenDialog(true)
    }

    function ConfirmDialog(props) {
        const { register, refetch, loading, openDialog, setOpenDialog } = props

        const handleClose = () => {
            setOpenDialog(false)
        }

        const deleteItem = () => (
            register({
                variables: {
                    id: deleteItemIndex
                }
            }).then(
                (response) => {
                    if (response.data.deleteTopic.found) {
                        notifySuccess("Topic deleted")
                        refetch()
                    }
                    handleClose()
                },
                () => {
                    notifyError("Delete topic failed, try again")
                    handleClose()
                }
            )
        )


        return (
            <div>
                <Dialog
                    open={openDialog}
                    TransitionComponent={ConfirmTransition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>
                        Delete topic
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            Do you confirm to delete the topic?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary" disabled={loading}>
                            Cancel
                        </Button>
                        <LoadingButton
                            color={"primary"}
                            variant={"contained"}
                            loading={loading}
                            onClick={deleteItem}
                        >
                            Confirm
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }

    return (
        <Mutation
            mutation={DELETE_TOPIC}
        >
            {
                (register, { loading: loadingDelete }) => (
                    <Box sx={{ width: "100%" }}>
                        <ConfirmDialog
                            openDialog={openDialog}
                            setOpenDialog={setOpenDialog}
                            register={register}
                            refetch={refetch}
                            loading={loadingDelete}
                        />
                        <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                            <TableHeaderBar
                                handleSearchInputChange={handleSearchInputChange}
                                searchValue={searchValue}
                                handleSearchValueChange={handleSearchValueChange}
                                showSearchInput={showSearchInput}
                                toggleSearchInput={toggleSearchInput}
                            />
                            <CardContent>
                                {
                                    loading
                                        ? <LoadingSkeleton />
                                        : !data?.allTopics?.edges?.length
                                            ? <TableNoData />
                                            : <TableContainer sx={{ maxHeight: "calc(70vh)" }}>
                                                <Table
                                                    sx={{ minWidth: 750 }}
                                                    aria-labelledby="tableTitle"
                                                    size={"small"}
                                                >
                                                    <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort}/>
                                                    <TableBody>
                                                        {data?.allTopics?.edges?.map((row) => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    tabIndex={-1}
                                                                    key={row.node.id}
                                                                    sx={tableStyles.row}
                                                                >
                                                                    <TableCell>{row.node.name}</TableCell>
                                                                    <TableCell>
                                                                        {row.node.description}
                                                                        <Box sx={tableStyles.actionColumn}>
                                                                            <Tooltip title={"Edit"}>
                                                                                <IconButton
                                                                                    color={"warning"}
                                                                                    size={"small"}
                                                                                    sx={{ m: "3px" }}
                                                                                    onClick={() => updateItem(row.node)}
                                                                                >
                                                                                    <EditOutlined fontSize={"small"} />
                                                                                </IconButton>
                                                                            </Tooltip>

                                                                            <Tooltip title={"Delete"}>
                                                                                <IconButton
                                                                                    color={"error"}
                                                                                    size={"small"}
                                                                                    sx={{ m: "3px" }}
                                                                                    onClick={() => deleteItem(row)}
                                                                                >
                                                                                    <DeleteOutlined fontSize={"small"} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 25]}
                                                                colSpan={3}
                                                                SelectProps={{
                                                                    inputProps: {
                                                                        'aria-label': 'rows per page',
                                                                    },
                                                                }}
                                                                count={totalItemsPagination}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer>
                                }
                            </CardContent>
                        </Card>
                    </Box>
                )
            }
        </Mutation>
    )
}