import * as React from 'react';

//material
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
} from "@mui/material"

//router
import { useNavigate } from "react-router-dom"

//styles
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"
import { CreditCard, InfoRounded } from "@mui/icons-material"

export default function UserSubscriptionPaymentForm() {
    const navigate = useNavigate()

    const TableHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar disableGutters sx={quizLayoutStyles.componentHeaderToolbar}>
                    <Box>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                            }}
                        >
                            Choose subscription plan
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        );
    }

    const TextTag = ({text}) => (
        <Box sx={{display: "inline-flex", background: "gray", color: "white", p: "5px"}}>
            <Typography variant={"caption"} component={"span"} sx={{fontWeight: 600}}>
                {text}
            </Typography>
        </Box>
    )

    //50 usd subscription per life
    const Button1 = () => (
        <form
            action="https://www.paypal.com/cgi-bin/webscr"
            method="post"
            target="_top"
        >
            <input  type="hidden" name="cmd" value="_s-xclick" />
            <input type="hidden" name="hosted_button_id" value="QP8Q6TJMCWQV8" />
            <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_paynowCC_LG.gif"
                border="0"
                name="submit"
                alt="PayPal - The safer, easier way to pay online!"
            />
            <img
                alt=""
                border="0"
                src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
            />
        </form>
    )

    return (
        <Box sx={{width: "100%"}}>
            <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                <TableHeaderBar/>
                <CardContent>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid container item xs={12} spacing={1}>
                                <Grid container item xs={"auto"} alignItems={"center"}>
                                    <InfoRounded/>
                                </Grid>
                                <Grid item xs={true}>
                                    <Typography variant={"subtitle1"}>
                                        3 devices limit for all plans
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} spacing={2} justifyContent={"center"}>
                                <Grid item xs={12} sm={4}>
                                    <Card variant={"outlined"} sx={{p: 2, border: "2px solid blue"}}>
                                        <Grid container spacing={2}>
                                            <Grid container item xs={12} justifyContent={"center"}>
                                                <CreditCard sx={{fontSize: 70}}/>
                                            </Grid>

                                            <Grid container item xs={12} justifyContent={"center"}>
                                                <Divider flexItem sx={{borderTop: "1px solid blue", width: 1}}/>
                                            </Grid>

                                            <Grid item xs={12} textAlign={"center"}>
                                                <Typography variant={"subtitle2"}>
                                                    Pay <TextTag text={"5 USD"} /> to get access to all available questionnaires for <TextTag text={"1 month"} />
                                                </Typography>
                                            </Grid>

                                            <Grid container item xs={12} justifyContent={"center"}>
                                                <Button1/>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Card variant={"outlined"} sx={{p: 2, border: "2px solid blue"}}>
                                        <Grid container spacing={2}>
                                            <Grid container item xs={12} justifyContent={"center"}>
                                                <CreditCard sx={{fontSize: 70}}/>
                                            </Grid>

                                            <Grid container item xs={12} justifyContent={"center"}>
                                                <Divider flexItem sx={{borderTop: "1px solid blue", width: 1}}/>
                                            </Grid>

                                            <Grid item xs={12} textAlign={"center"}>
                                                <Typography variant={"subtitle2"}>
                                                    Pay <TextTag text={"15 USD"} /> to get access to all available questionnaires for <TextTag text={"6 months"} />
                                                </Typography>
                                            </Grid>

                                            <Grid container item xs={12} justifyContent={"center"}>
                                                <Button1/>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Card variant={"outlined"} sx={{p: 2, border: "2px solid blue"}}>
                                        <Grid container spacing={2}>
                                            <Grid container item xs={12} justifyContent={"center"}>
                                                <CreditCard sx={{fontSize: 70}}/>
                                            </Grid>

                                            <Grid container item xs={12} justifyContent={"center"}>
                                                <Divider flexItem sx={{borderTop: "1px solid blue", width: 1}}/>
                                            </Grid>

                                            <Grid item xs={12} textAlign={"center"}>
                                                <Typography variant={"subtitle2"}>
                                                    Pay <TextTag text={"25 USD"} /> to get access to all available questionnaires for <TextTag text={"1 year"} />
                                                </Typography>
                                            </Grid>

                                            <Grid container item xs={12} justifyContent={"center"}>
                                                <Button1/>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Card variant={"outlined"} sx={{p: 2, border: "2px solid blue"}}>
                                        <Grid container spacing={2}>
                                            <Grid container item xs={12} justifyContent={"center"}>
                                                <CreditCard sx={{fontSize: 70}}/>
                                            </Grid>

                                            <Grid container item xs={12} justifyContent={"center"}>
                                                <Divider flexItem sx={{borderTop: "1px solid blue", width: 1}}/>
                                            </Grid>

                                            <Grid item xs={12} textAlign={"center"}>
                                                <Typography variant={"subtitle2"}>
                                                    Pay <TextTag text={"50 USD"} /> to get access to all available questionnaires <TextTag text={"forever"} />
                                                </Typography>
                                            </Grid>

                                            <Grid container item xs={12} justifyContent={"center"}>
                                                <Button1/>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Box sx={{display: "flex", justifyContent: "right", p: 1, m: 1}}>
                            <Button
                                variant={"contained"}
                                size={"small"}
                                sx={{m: 1}}
                                onClick={() => navigate("/quiz/subscription")}
                            >
                                Done
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}