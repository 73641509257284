import * as React from 'react';

//material
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
    Card,
    CardContent,
    Grid,
    Button,
    Avatar,
    CircularProgress,
    Paper,
    Divider,
    Tooltip,
    Backdrop
} from "@mui/material"
import {
    DescriptionOutlined,
    SkipNextOutlined,
    SkipPreviousOutlined,
} from "@mui/icons-material"

//router
import { useNavigate, useParams } from "react-router-dom"

//apollo
import { useMutation, useQuery } from "@apollo/client"
import { CREATE_ANSWER_EXAM, CURRENT_CLIENT, EXAM_SUMMARY } from "../../queries/auth/userExamQueries"
import { LOAD_EXAM_STATUS } from "../../queries/admin/questionnaireQueries"

//components
import { ErrorNotification } from "../../components/Notifications/Notifications"
import QuestionSelect from "../../components/QuestionSelect"

//styles
import userExamPageStyles from "../../assets/styles/userExamPageStyles"
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"
import { grayColor, whiteColor } from "../../assets/styles/utils"

//redux
import { useSelector } from "react-redux"
import Timer from "../../components/Timer"

export default function UserExam() {
    const navigate = useNavigate()
    const authUser = useSelector((state) => state.loginReducer)

    const { questionnaireId, examId } = useParams()

    const notifyError = message => ErrorNotification(message)

    const [selectedQuestion, setSelectedQuestion] = React.useState(null)
    const [selectedAnswer, setSelectedAnswer] = React.useState(null)

    const [questionsRemaining, setQuestionsRemaining] = React.useState(true)

    const [questionnaireTime, setQuestionnaireTime] = React.useState(null)
    const [questionnaireStartTime, setQuestionnaireStartTime] = React.useState(null)
    const [questionnaireElapsedTime, setQuestionnaireElapsedTime] = React.useState(0)

    const {loading: loadingClient} = useQuery(
        CURRENT_CLIENT,
        {
            variables: {
                id: authUser.client.id
            },
            onCompleted: data => {
              if (data?.client?.currentExamRealId && data?.client?.currentQuestionnaireStartTimeReal) {
                  setQuestionnaireStartTime(data.client.currentQuestionnaireStartTimeReal)
              }
            },
            fetchPolicy: "network-only"
        }
    )

    const [loadingNextQuestion, setLoadingNextQuestion] = React.useState(false)
    const { data: examStatus, loading: loadingExamStatus } = useQuery(
        LOAD_EXAM_STATUS,
        {
            variables: {examId: examId},
            onCompleted: data => {
                if (data?.examRealStatus?.questionStatusList?.length > 0) {
                    //start loading next question indicator
                    setLoadingNextQuestion(true)

                    setQuestionnaireTime(data.examRealStatus.examReal.questionnaire.time)

                    let nextQuestionFound = false

                    let selectedQuestionIndex = -1
                    if (selectedQuestion) {
                        selectedQuestionIndex = data.examRealStatus.questionStatusList.findIndex(item => item.question.id === selectedQuestion.question.id)
                    }

                    data.examRealStatus.questionStatusList.every((item, index) => {
                        if (selectedQuestionIndex < index && !item.wasAnswered) {
                            setSelectedQuestion(item)

                            setSelectedAnswer(null)

                            nextQuestionFound = true

                            return false
                        }

                        return true
                    })

                    if (!nextQuestionFound) {
                        data.examRealStatus.questionStatusList.every((item, index) => {
                            if (selectedQuestionIndex >= index && !item.wasAnswered) {
                                setSelectedQuestion(item)

                                setSelectedAnswer(null)

                                nextQuestionFound = true

                                return false
                            }

                            return true
                        })
                    }

                    if (!nextQuestionFound) {
                        setQuestionsRemaining(false)
                    }

                    //stop loading next question indicator
                    setLoadingNextQuestion(false)
                }
            }
        }
    )

    React.useEffect(() => {
        if (questionnaireTime && questionnaireStartTime) {
            const now = new Date().getTime()
            const elapsedTime = Math.trunc(now / 1000) - questionnaireStartTime

            if (elapsedTime) {
                setQuestionnaireElapsedTime(elapsedTime)
            } else {
                setQuestionnaireElapsedTime(1)
            }
        }
    }, [questionnaireTime, questionnaireStartTime])

    const { data: dataExamSummary, loading: loadingExamSummary } = useQuery(
        EXAM_SUMMARY,
        {
            variables: {examId: examId}
        }
    )

    const [createAnswer, {loading: loadingSaveAnswer}] = useMutation(
        CREATE_ANSWER_EXAM,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: LOAD_EXAM_STATUS,
                    variables: { examId: examId},
                },
                {
                    query: EXAM_SUMMARY,
                    variables: { examId: examId},
                }
            ]
        }
    )

    const TableHeaderBar = () => {
        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar disableGutters sx={quizLayoutStyles.componentHeaderToolbar}>
                    <Grid container>
                        <Grid item xs={true}>
                            <Typography
                                variant="h6"
                                noWrap
                                sx={{
                                    mr: 2,
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                Quiz {examStatus?.examRealStatus?.examReal?.questionnaire?.topic.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={"auto"}>
                            {
                                (loadingExamStatus || loadingExamSummary || loadingClient) && <CircularProgress color={"inherit"} size={24}/>
                            }
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }

    const optionLetters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S",
        "T", "U", "V", "W", "X", "Y", "Z"]

    function handleStopQuiz() {
        navigate(`/quiz/questionnaire/${questionnaireId}`)
    }

    const AnswersSection = () => {
        function handleSelectAnswer(item) {
            if (!selectedAnswer) {
                const questionnaireQuestionIdIndex = selectedQuestion.question.questionQuestionnaireQuestion.edges.findIndex(
                    questionnaireQuestionItem => questionnaireQuestionItem.node.questionnaire.id === questionnaireId
                )

                if (questionnaireQuestionIdIndex > -1) {
                    //set answer
                    setSelectedAnswer(item)

                    createAnswer({
                        variables: {
                            exam: examId,
                            questionnaireQuestion: selectedQuestion.question.questionQuestionnaireQuestion.edges[questionnaireQuestionIdIndex].node.id,
                            possibleAnswer: item.node.id
                        }
                    }).then(async response => {
                        if (!response?.data?.createAnswerReal?.answerReal?.id) {
                            setSelectedAnswer(null)
                            notifyError("Save selected answer failed, try again")
                        }
                    }).catch(() => {
                        notifyError("Something were wrong while saving answer")
                    })
                }
            }
        }

        return (
            <Grid container item xs={12}>
                {
                    selectedQuestion?.question.questionPossibleAnswer?.edges.map((item, index) => (
                        <Grid
                            key={item.node.id}
                            container
                            item
                            xs={12}
                            sx={userExamPageStyles.answerOption}
                            style={selectedAnswer?.node?.id === item.node.id ? {background: "rgba(53,197,60,0.7)"} : null}
                            onClick={() => handleSelectAnswer(item)}
                        >
                            <Grid item xs={"auto"} sx={{mr: 1}}>
                                <Avatar
                                    sx={{ width: 30, height: 30 }}
                                    style={selectedAnswer?.node?.id === item.node.id ? {background: "rgba(25,118,210,0.8)"} : null}
                                >
                                    {optionLetters[index % 26]}
                                </Avatar>
                            </Grid>
                            <Grid item xs={true}>
                                <Typography component={"div"} variant={"body1"}>
                                    {item.node.description}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))
                }
            </Grid>
        )
    }

    function handleNextQuestion() {
        if (examStatus?.examRealStatus?.questionStatusList?.length > 0) {
            if (selectedQuestion) {
                let nextQuestionFound = false

                let selectedQuestionIndex = -1
                examStatus.examRealStatus.questionStatusList.every((item, index) => {
                    if (selectedQuestionIndex > -1  && !item.wasAnswered) {
                        setSelectedQuestion(item)

                        setSelectedAnswer(null)

                        nextQuestionFound = true

                        return false
                    }

                    if (item.question.id === selectedQuestion.question.id) {
                        selectedQuestionIndex = index
                    }

                    return true
                })

                //star search from the beginning
                if (!nextQuestionFound) {
                    examStatus.examRealStatus.questionStatusList.every((item, index) => {
                        if (index <= selectedQuestionIndex && !item.wasAnswered) {
                            setSelectedQuestion(item)

                            setSelectedAnswer(null)

                            return false
                        }

                        return true
                    })
                }
            }
        }
    }

    function handlePrevQuestion() {
        if (examStatus?.examRealStatus?.questionStatusList?.length > 0) {
            if (selectedQuestion) {
                let prevQuestionFound = false

                let selectedQuestionIndex = -1
                const reverseArray = Array.from(examStatus.examRealStatus.questionStatusList).reverse()

                reverseArray.every((item, index) => {
                    if (selectedQuestionIndex > -1  && !item.wasAnswered) {
                        setSelectedQuestion(item)

                        setSelectedAnswer(null)

                        prevQuestionFound = true

                        return false
                    }

                    if (item.question.id === selectedQuestion.question.id) {
                        selectedQuestionIndex = index
                    }

                    return true
                })

                //star search from the beginning
                if (!prevQuestionFound) {
                    reverseArray.every((item, index) => {
                        if (index <= selectedQuestionIndex && !item.wasAnswered) {
                            setSelectedQuestion(item)

                            setSelectedAnswer(null)

                            return false
                        }

                        return true
                    })
                }
            }
        }
    }

    return (
        <Box sx={{width: "100%"}}>
            <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                <TableHeaderBar/>
                {
                    (loadingSaveAnswer || loadingNextQuestion)
                    && <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
                <CardContent sx={{ maxHeight: "calc(70vh)", overflowY: "auto"}}>
                    <Grid container alignItems={"flex-start"} spacing={1}>
                        <Grid item xs={12} md={6} sx={{order: {xs: 2, md: 1}}}>
                            <Paper elevation={2} sx={{ p: 1}}>
                                {
                                    examStatus?.examRealStatus?.questionStatusList?.length > 0
                                    && <QuestionSelect
                                        questionStatusList={examStatus.examRealStatus.questionStatusList}
                                        selectedQuestion={selectedQuestion}
                                        setSelectedQuestion={setSelectedQuestion}
                                        setSelectedAnswer={setSelectedAnswer}
                                    />
                                }
                            </Paper>
                        </Grid>

                        <Grid container item md={6} alignItems={"center"} rowSpacing={2} sx={{order: {xs: 1, md: 2}}}>
                            <Grid container item justifyContent={"space-around"}>
                                <Grid item xs={"auto"}>
                                    <Avatar sx={{ width: 55, height: 55, fontSize: "16px" }}>
                                        <Grid container justifyContent={"center"} alignItems={"center"}>
                                            <Grid container item justifyContent={"center"} xs={12} sx={{color: "#23395d"}}>
                                                {examStatus?.examRealStatus?.questionStatusList?.length}
                                            </Grid>
                                            <Divider flexItem sx={{borderColor: whiteColor, width: 3/4, mt: "2px", mb: "4px"}}/>
                                            <Grid container item justifyContent={"center"} xs={12}>
                                                {dataExamSummary?.examRealSummary?.answersTotal}
                                            </Grid>
                                        </Grid>
                                    </Avatar>
                                </Grid>

                                <Grid container item xs={"auto"} alignItems={"center"}>
                                    {
                                        questionnaireElapsedTime
                                        && <Timer
                                            isPlaying={questionsRemaining}
                                            duration={questionnaireTime}
                                            initialRemainingTime={
                                                questionnaireTime > questionnaireElapsedTime
                                                    ? questionnaireTime - questionnaireElapsedTime
                                                    : 0
                                            }
                                            inTime={questionnaireTime > questionnaireElapsedTime}
                                        />
                                    }
                                </Grid>
                            </Grid>

                            {
                                selectedQuestion
                                && <Grid container item xs={12}>
                                    <Card variant={"outlined"} sx={{p: 1, width: 1}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant={"subtitle2"}>
                                                    {selectedQuestion?.question?.description}
                                                </Typography>
                                                <Typography variant={"caption"} sx={{ display: "flex", alignItems: "center" }}>
                                                    <DescriptionOutlined sx={{ fontSize: 14 }} />
                                                    {selectedQuestion?.question?.topic?.name}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <AnswersSection />
                                            </Grid>

                                            {
                                                questionsRemaining
                                                && <Grid container item xs={12} justifyContent={"center"}>
                                                    <Tooltip title="Prev">
                                                        <Button
                                                            aria-label="previous question"
                                                            variant={"outlined"}
                                                            sx={{ mr: 1 }}
                                                            startIcon={<SkipPreviousOutlined />}
                                                            onClick={handlePrevQuestion}
                                                        >
                                                            Prev
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Next">
                                                        <Button
                                                            aria-label="next question"
                                                            variant={"outlined"}
                                                            startIcon={<SkipNextOutlined />}
                                                            onClick={handleNextQuestion}
                                                        >
                                                            Next
                                                        </Button>
                                                    </Tooltip>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Card>
                                </Grid>
                            }

                            <Grid container item xs={12} justifyContent={"flex-end"} sx={{mt: 1}}>
                                <Button
                                    color={"secondary"}
                                    variant={"contained"}
                                    size={"small"}
                                    sx={{m: 1}}
                                    onClick={handleStopQuiz}
                                >
                                    Leave quiz
                                </Button>

                                <Button
                                    color={"primary"}
                                    variant={"contained"}
                                    size={"small"}
                                    sx={{m: 1}}
                                    onClick={() => navigate(`/quiz/questionnaire/${questionnaireId}/exam/${examId}/summary`)}
                                >
                                    Finish
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}