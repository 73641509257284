import React from "react"

//redux
import { useDispatch, useSelector } from "react-redux"
import { Avatar, Box, Divider, Grid, IconButton, Tooltip } from "@mui/material"
import quizLayoutStyles from "../assets/styles/quizLayoutStyles"
import {
    AccountCircleOutlined,
    BarChartOutlined,
    BookmarkOutlined,
    DashboardOutlined,
    ListAltOutlined,
    LogoutOutlined,
    ManageSearchOutlined,
    PaymentOutlined,
    PlayArrowOutlined,
    QuestionMarkOutlined,
    TopicOutlined
} from "@mui/icons-material"

import { Link as RouterLink, useLocation } from "react-router-dom"
import { useApolloClient, useMutation, useQuery } from "@apollo/client"
import { singOut } from "../actions/auth/loginActions"
import { DEVICE_CHECK, REFRESH_TOKEN } from "../queries/auth/loginQueries"

import Cookies from "js-cookie"

import { ErrorNotification } from "../components/Notifications/Notifications"

//logo
import logoIcon from"../assets/images/logoIcon.svg"

const notifyError = message => ErrorNotification(message)

export default function AuthLayout(props) {
    const dispatch = useDispatch()
    const client = useApolloClient()
    const location = useLocation()

    const handleLogOut = () => {
        //window.open("/", "_self")
        dispatch(singOut())
        client.clearStore()
    }

    useQuery(
        DEVICE_CHECK,
        {
            onCompleted: data => {
                if (data.deviceCheck.isRegistered === false) {
                    notifyError("User account can only be used in a maximum of 3 devices")
                    handleLogOut()
                }
            },
        }
    )

    const authUser = useSelector((state) => state.loginReducer)
    const storedRefreshToken = localStorage.getItem("refreshToken")

    const [getNewToken] = useMutation(REFRESH_TOKEN, {
        variables: {
            refreshToken: storedRefreshToken,
        },
        onCompleted: (data) => {
            if (data.refreshToken.success) {
                const token = data.refreshToken.token
                const refreshToken = data.refreshToken.refreshToken
                Cookies.set("JWT", token, { secure: true, sameSite: "Strict", expires: 15 })
                localStorage.setItem("refreshToken", refreshToken)
            }
        },
        onError: () => {
            notifyError("Automatically credentials sync failed. Re authenticate to eliminate this issue")
        },
    })

    React.useEffect(() => {
        if (storedRefreshToken) {
            getNewToken()
        }
        // eslint-disable-next-line
    }, [])

    //known if authenticated user is admin. a user is admin if isStaff = true or isSuperuser = true or belongs to a group named "admin"
    function isAdminUser(user) {
        if (user.isStaff || user.isSuperuser) {
            return true
        }
        return user.groups.edges.findIndex((group) => group.node.name.toUpperCase() === "ADMIN") > -1
    }

    const NavButtons = () => (
        <Box sx={{ display: "flex", overflowX: "auto", alignItems: "center" }}>
            <Box sx={{ display: "flex" }}>
                <Tooltip title={"Dashboard"}>
                    <IconButton
                        sx={
                            location.pathname.includes("/quiz/dashboard")
                                ? quizLayoutStyles.headerButtonSelected
                                : quizLayoutStyles.headerButton
                        }
                        component={RouterLink}
                        to={"/quiz/dashboard"}
                    >
                        <DashboardOutlined />
                    </IconButton>
                </Tooltip>

                <Tooltip title={"Start quiz"}>
                    <IconButton
                        sx={
                            location.pathname.includes("/quiz/questionnaire")
                                ? quizLayoutStyles.headerButtonSelected
                                : quizLayoutStyles.headerButton
                        }
                        component={RouterLink}
                        to={"/quiz/questionnaire"}
                    >
                        <PlayArrowOutlined />
                    </IconButton>
                </Tooltip>

                <Tooltip title={"Glossary"}>
                    <IconButton
                        sx={
                            location.pathname.includes("/quiz/glossary")
                                ? quizLayoutStyles.headerButtonSelected
                                : quizLayoutStyles.headerButton
                        }
                        component={RouterLink}
                        to={"/quiz/glossary"}
                    >
                        <ManageSearchOutlined />
                    </IconButton>
                </Tooltip>
            </Box>

            <Box sx={{ display: "flex" }}>
                <Divider orientation="vertical" flexItem sx={quizLayoutStyles.headerDividerButton} />
            </Box>

            <Box sx={{ display: "flex" }}>
                <Tooltip title={"Subscription payment"}>
                    <IconButton
                        sx={
                            location.pathname.includes("/quiz/subscription")
                                ? quizLayoutStyles.headerButtonSelected
                                : quizLayoutStyles.headerButton
                        }
                        component={RouterLink}
                        to={"/quiz/subscription"}
                    >
                        <PaymentOutlined />
                    </IconButton>
                </Tooltip>

                <Tooltip title={"Profile"}>
                    <IconButton
                        sx={
                            location.pathname.includes("/quiz/profile")
                                ? quizLayoutStyles.headerButtonSelected
                                : quizLayoutStyles.headerButton
                        }
                        component={RouterLink}
                        to={"/quiz/profile"}
                    >
                        <AccountCircleOutlined />
                    </IconButton>
                </Tooltip>
            </Box>

            {
                isAdminUser(authUser)
                && <>
                    <Box sx={{ display: "flex" }}>
                        <Divider orientation="vertical" flexItem sx={quizLayoutStyles.headerDividerButton} />
                    </Box>

                    <Box sx={{ display: "flex" }}>
                        <Tooltip title={"Mange topic"}>
                            <IconButton
                                sx={
                                    location.pathname.includes("/quiz/manage-topics")
                                        ? quizLayoutStyles.headerButtonSelected
                                        : quizLayoutStyles.headerButton
                                }
                                component={RouterLink}
                                to={"/quiz/manage-topics"}
                            >
                                <TopicOutlined />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={"Manage question"}>
                            <IconButton
                                sx={
                                    location.pathname.includes("/quiz/manage-questions")
                                        ? quizLayoutStyles.headerButtonSelected
                                        : quizLayoutStyles.headerButton
                                }
                                component={RouterLink}
                                to={"/quiz/manage-questions"}
                            >
                                <QuestionMarkOutlined />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={"Manage questionnaire"}>
                            <IconButton
                                sx={
                                    location.pathname.includes("/quiz/manage-questionnaire")
                                        ? quizLayoutStyles.headerButtonSelected
                                        : quizLayoutStyles.headerButton
                                }
                                component={RouterLink}
                                to={"/quiz/manage-questionnaire"}
                            >
                                <ListAltOutlined />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={"Manage glossary"}>
                            <IconButton
                                sx={
                                    location.pathname.includes("/quiz/manage-glossary")
                                        ? quizLayoutStyles.headerButtonSelected
                                        : quizLayoutStyles.headerButton
                                }
                                component={RouterLink}
                                to={"/quiz/manage-glossary"}
                            >
                                <BookmarkOutlined />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={"Admin stats"}>
                            <IconButton
                                sx={
                                    location.pathname.includes("/quiz/admin-stats")
                                        ? quizLayoutStyles.headerButtonSelected
                                        : quizLayoutStyles.headerButton
                                }
                                component={RouterLink}
                                to={"/quiz/admin-stats"}
                            >
                                <BarChartOutlined />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </>
            }

            <Box sx={{ display: "flex" }}>
                <Divider orientation="vertical" flexItem sx={quizLayoutStyles.headerDividerButton} />
            </Box>

            <Tooltip title={"Log out"}>
                <IconButton sx={quizLayoutStyles.headerButton} onClick={handleLogOut}>
                    <LogoutOutlined />
                </IconButton>
            </Tooltip>
        </Box>
    )

    return (
        <Box sx={quizLayoutStyles.background}>
            <Box sx={quizLayoutStyles.container}>
                <Box sx={quizLayoutStyles.header}>
                    <NavButtons/>
                </Box>

                <Box sx={quizLayoutStyles.content}>
                    {props.children}
                </Box>

                <Box sx={quizLayoutStyles.footer}>
                    <Grid container alignItems={"center"} justifyContent={"center"}>
                        <Grid item container alignItems={"center"} xs={"auto"}>
                            Real State Quiz
                        </Grid>
                        <Grid item container alignItems={"center"} xs={"auto"}>
                            <Avatar src={logoIcon} sx={{m: 1, pt: "8px"}}/>
                        </Grid>

                        <Grid item container alignItems={"center"} xs={"auto"}>
                            2022
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}
