import * as React from "react"

//material
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import {
    Card,
    CardContent,
    TableSortLabel,
    TextField
} from "@mui/material"
import {
    CancelOutlined,
    SearchOffOutlined,
    SearchOutlined
} from "@mui/icons-material"

//apollo
import { useQuery } from "@apollo/client"
import { LOAD_ALL_GLOSSARIES } from "../../queries/admin/glossaryQueries"

//styles
import tableStyles from "../../assets/styles/tableStyles"
import quizLayoutStyles from "../../assets/styles/quizLayoutStyles"

//components
import { LoadingSkeleton, TableNoData } from "../../utils/tableUtils"
import { visuallyHidden } from "@mui/utils"
import TablePagination from "@mui/material/TablePagination"
import TableFooter from "@mui/material/TableFooter"

export default function UserGlossary() {
    //pagination
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('word')
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)

    //save server response data (page info)
    const [totalItemsPagination, setTotalItemsPagination] = React.useState(-1)
    const [startCursorPagination, setStartCursorPagination] = React.useState(null)
    const [endCursorPagination, setEndCursorPagination] = React.useState(null)

    const [queryVariables, setQueryVariables] = React.useState({
        orderBy: order === "asc" ? [orderBy] : [`-${orderBy}`],
        wordFilter: null,
        first: rowsPerPage,
        last: null,
        beforeCursor: null,
        afterCursor: null
    })

    const setPaginationResponse = (data) => [
        setTotalItemsPagination(data.allGlossaries.totalCount),
        setStartCursorPagination(data.allGlossaries.pageInfo.startCursor),
        setEndCursorPagination(data.allGlossaries.pageInfo.endCursor)
    ]

    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            setQueryVariables({
                ...queryVariables,

                first: rowsPerPage,
                last: null,
                beforeCursor: null,
                afterCursor: endCursorPagination
            })
        } else {
            setQueryVariables({
                ...queryVariables,

                first: null,
                last: rowsPerPage,
                beforeCursor: startCursorPagination,
                afterCursor: null
            })
        }
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)

        setQueryVariables({
            ...queryVariables,

            first: parseInt(event.target.value, 10),
            last: null,
            beforeCursor: null,
            afterCursor: null
        })
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
        setPage(0)

        setQueryVariables({
            ...queryVariables,

            orderBy: order === "asc" ? [property] : [`-${property}`],
            first: rowsPerPage,
            last: null,
            beforeCursor: null,
            afterCursor: null
        })
    }

    const handleSearchInputChange = (nameValue) => {
        setPage(0)

        setQueryVariables({
            ...queryVariables,

            wordFilter: nameValue,

            first: rowsPerPage,
            last: null,
            beforeCursor: null,
            afterCursor: null
        })
    }

    //display search field
    const [showSearchInput, setShowSearchInput] = React.useState(false)
    const toggleSearchInput = () => {
        setShowSearchInput(!showSearchInput)
    }

    //search field
    const [searchValue, setSearchValue] = React.useState("")

    const handleSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }

    const { data, loading } = useQuery(LOAD_ALL_GLOSSARIES, {
        variables: queryVariables,
        onCompleted: data1 => {
            setPaginationResponse(data1)
        },
        fetchPolicy: "network-only"
    })

    const TableHeaderBar = (props) => {
        const {
            handleSearchInputChange,
            searchValue,
            handleSearchValueChange,
            showSearchInput,
            toggleSearchInput,
        } = props

        const handlePressEnter = (e) => {
            if (e.keyCode === 13) {
                applySearch()
            }
        }

        const applySearch = () => {
            handleSearchInputChange(searchValue)
            toggleSearchInput()
        }

        const cancelFilters = () => {
            setSearchValue("")
            handleSearchInputChange("")
            toggleSearchInput()
        }

        return (
            <AppBar position="static" color={"transparent"}>
                <Toolbar disableGutters sx={quizLayoutStyles.componentHeaderToolbar}>
                    {
                        showSearchInput
                            ? <TextField
                                placeholder={"Search by term..."}
                                autoFocus
                                size={"small"}
                                fullWidth
                                InputProps={{
                                    endAdornment:
                                        <Tooltip title={"Cancel search"}>
                                            <IconButton size={"small"} onClick={cancelFilters}>
                                                <CancelOutlined />
                                            </IconButton>
                                        </Tooltip>,
                                    startAdornment:
                                        <Tooltip title={"Search by word"}>
                                            <IconButton size={"small"} onClick={applySearch}>
                                                <SearchOutlined />
                                            </IconButton>
                                        </Tooltip>


                                }}
                                value={searchValue}
                                onChange={handleSearchValueChange}
                                onKeyDown={handlePressEnter}
                            />
                            : <>
                                <Box>
                                    <Typography
                                        variant="h6"
                                        noWrap
                                        sx={{
                                            mr: 2,
                                            fontFamily: "monospace",
                                            fontWeight: 700,
                                            letterSpacing: ".3rem",
                                            color: "inherit",
                                            textDecoration: "none"
                                        }}
                                    >
                                        Glossary
                                    </Typography>
                                </Box>

                                <Box sx={{display: "flex"}}>
                                    <Tooltip title="Filter data">
                                        <IconButton
                                            aria-label="filter data"
                                            color={searchValue ? "secondary" : "default"}
                                            onClick={toggleSearchInput}
                                        >
                                            {
                                                searchValue ? <SearchOutlined /> : <SearchOffOutlined />
                                            }
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </>
                    }
                </Toolbar>
            </AppBar>
        )
    }

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } = props

        const headCells = [
            {
                id: "word",
                label: "Word",
                sort: true,
            },
            {
                id: "meaning",
                label: "Meaning",
                sort: true,
            }
        ]

        const createSortHandler = (property) => () => {
            onRequestSort(property);
        }

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={"left"}
                            padding={'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {
                                headCell.sort
                                    ? <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : "asc"}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === "desc" ? "sorted descending" : "sorted ascending"}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                    : headCell.label
                            }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Card variant={"outlined"} sx={quizLayoutStyles.componentCardContainer}>
                <TableHeaderBar
                    handleSearchInputChange={handleSearchInputChange}
                    searchValue={searchValue}
                    handleSearchValueChange={handleSearchValueChange}
                    showSearchInput={showSearchInput}
                    toggleSearchInput={toggleSearchInput}
                />
                <CardContent>
                    {
                        loading
                            ? <LoadingSkeleton />
                            : !data?.allGlossaries?.edges?.length
                                ? <TableNoData />
                                : <TableContainer sx={{ maxHeight: "calc(70vh)" }}>
                                    <Table
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        size={"small"}
                                    >
                                        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort}/>
                                        <TableBody>
                                            {data?.allGlossaries?.edges?.map((row) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={row.node.id}
                                                        sx={tableStyles.row}
                                                    >
                                                        <TableCell>{row.node.word}</TableCell>
                                                        <TableCell>{row.node.meaning}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 25]}
                                                    colSpan={2}
                                                    SelectProps={{
                                                        inputProps: {
                                                            'aria-label': 'rows per page',
                                                        },
                                                    }}
                                                    count={totalItemsPagination}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                    }
                </CardContent>
            </Card>
        </Box>
    )
}