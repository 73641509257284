import { gql } from "@apollo/client"

//client for logged-in user
export const CURRENT_CLIENT = gql`
    query client($id: ID!) {
        client(id: $id) {
            id
            currentExamId
            currentExamIdCodified
            currentExamRealId
            currentExamIdReal
            currentQuestionnaireStartTimeReal
        }
    }
`

//requesting a next question for a given exam
export const QUESTIONNAIRE_NEXT_QUESTION = gql`
    query nextQuestion($clientId: ID!) {
        nextQuestion(clientId: $clientId) {
            questionnaireQuestion {
                id
                question {
                    id
                    topic {
                        id
                        name
                        description
                    }
                    description
                    explanation
                    questionPossibleAnswer {
                        edges {
                            node {
                                id
                                description
                                isCorrect
                            }
                        }
                    }
                    questionUrl {
                        edges {
                            node {
                                id
                                url
                            }
                        }
                    }
                    questionCite {
                        edges {
                            node {
                                id
                                cite
                            }
                        }
                    }
                }
            }
            reason
            seconds
            paginationSize
            paginationItemNo
            paginationHasPrevious
            paginationHasNext
        }
    }
`

//initializing a new training exam
export const INITIALIZE_TRAINING = gql`
    mutation createExam($clientId: ID! $questionnaireId: ID! $finishTime: Int) {
        createExam(input: { client: $clientId questionnaire: $questionnaireId finishTime: $finishTime }) {
            exam {
                id
                client {
                    id
                }
            }
        }
    }
`

//initializing a new exam
export const INITIALIZE_EXAM = gql`
    mutation createExamReal($clientId: ID! $questionnaireId: ID! $finishTime: Int) {
        createExamReal(input: { client: $clientId questionnaire: $questionnaireId finishTime: $finishTime }) {
            examReal {
                id
                client {
                    id
                }
            }
        }
    }
`

//exam questionnaire to resume stated exam
export const EXAM_QUESTIONNAIRE = gql`
    query exam($id: ID!) {
        exam(id: $id) {
            id
            questionnaire {
                id
            }
        }
    }
`

//save selected answer
export const CREATE_ANSWER_EXAM_TRAINING = gql`
    mutation createAnswer($exam: ID! $possibleAnswer: ID! $questionnaireQuestion: ID!) {
        createAnswer(input: { exam: $exam possibleAnswer: $possibleAnswer questionnaireQuestion: $questionnaireQuestion }) {
            answer {
                id
            }
        }
    }
`

//save selected answer
export const CREATE_ANSWER_EXAM = gql`
    mutation createAnswerReal($exam: ID! $possibleAnswer: ID! $questionnaireQuestion: ID!) {
        createAnswerReal(input: { examReal: $exam possibleAnswer: $possibleAnswer questionnaireQuestion: $questionnaireQuestion }) {
            answerReal {
                id
            }
        }
    }
`

//save user feedback
export const CREATE_FEEDBACK = gql`
    mutation createFeedback($questionId: ID! $clientId: ID! $feedback: String!) {
        createFeedback(input: { question: $questionId client: $clientId feedback: $feedback }) {
            feedback {
                id
            }
        }
    }
`

//exam training summary
export const TRAINING_SUMMARY = gql`
    query examSummary($examId: String!) {
        examSummary(examId: $examId) {
            answersTotal
            questionsCorrect
            questionsInCorrect
            exam {
                finishTime
                clarification
                questionnaire {
                    id
                    topic {
                        id
                        name
                    }
                    description
                    time
                }
            }
        }
    }
`

//exam summary
export const EXAM_SUMMARY = gql`
    query examRealSummary($examId: String!) {
        examRealSummary(examRealId: $examId) {
            examReal {
                id
                finishTime
                questionnaire {
                    id
                    topic {
                        id
                        name
                    }
                    description
                    time
                }
            }
            questionsCorrect
            questionsInCorrect
            answersTotal
        }
    }
`

//client exams information
export const CLIENT_EXAM_STATS = gql`
    query client($id: ID!) {
        client(id: $id) {
            id
            currentExamId
            currentExamIdCodified
            currentQuestionnaireStartTime
            clientExam {
                edges {
                    node {
                        id
                            questionnaire {
                            id
                            topic {
                                name
                            }
                        }
                        finishTime
                    }
                }
            }
            clientExamReal {
                edges {
                    node {
                        id
                        questionnaire {
                            id
                            topic {
                                name
                            }
                        }
                        finishTime
                    }
                }
            }
        }
    }
`

//exam details
export const EXAM_ANSWER_DETAILS = gql`
    query examReal($id: ID!) {
        examReal(id: $id) {
            id
            examRealAnswerReal{
                edges{
                    node{
                        possibleAnswer{
                            id
                            isCorrect
                        }
                        questionnaireQuestion{
                            id
                        }
                    }
                }
            }
        }
    }
`